import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../Css/main.css';
import Sidemenu from '../Sidemenu';
import axios from 'axios';
import FormItems from './FormItems';
import {Elements} from './OfferDetailConfig';
import EasyTable from '../Tables/EasyTable';
import { useGridApiRef } from '@mui/x-data-grid';
import OfferPreview from './OfferPreview';
import Loading from '../Helper/Loading';



function OfferDetail() {
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  const [data, setData] = useState([]);
  const [changedUser, setChangedUser] = useState(false);
  const [offerOverview, setOfferOverview] = useState({});
  const [offerData, setOfferData] = useState({});
  const [clientData, setClientData] = useState([]);
  const [itemDeleted, setItemDeleted] = useState(false);

  const [selectionData, setSelectionData] = useState([]);
  const [productData, setProductData] = useState(global.config.productsdata.length ? global.config.productsdata : []);
  

  const [activePage, setActivePage] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams();

  const [curFilter, setCurFilter] = useState(1);
  const [productGroups, setProductGroups] = useState();
  const [productVariations, setProductVariations] = useState();
  const [chosenProduct, setChosenProduct] = useState({});

  const [offerStatus, setOfferStatus] = useState(false);
  

  const [offerid, setOfferid] = useState(searchParams.get("offer") ?? null);
  let newItem = searchParams.get("new") ? JSON.parse(searchParams.get("new")) : false;
  let newFromFrontend = searchParams.get("products") ? searchParams.get("products") : false;

  const apiRefTable = useGridApiRef();
  const formRef = useRef(null);

  let user = JSON.parse(localStorage.getItem('kerion_user')); 
  let language = localStorage.getItem("kerion_language");

  if(!localStorage.getItem('kerion_user')) {
    window.location.href = '/login';
  }

  useEffect(() => {

    if(!productData.length) {
      axios.get(process.env.REACT_APP_URL + 'getData.php')
      .then(response =>  {
        console.log(response);
        setProductData(response.data);
        global.config.productsdata = response.data;

      })
      .catch(error => console.log(error));
    }

    if(offerid) {
      axios.get(process.env.REACT_APP_URL + 'getData.php?offer=' + offerid)
      .then(response =>  {
        console.log(response);
        if(response.data[0]) {
          setOfferOverview(response.data[0]);
          console.log(response);
          let newOfferData = {};

          if(response.data.length) {
            global.config.productsdata.map(function(value, index) {
                response.data.map(function(offerproduct, idx) {
                  if(value.anr == offerproduct.anr) {
                    newOfferData[index] = value;
                    newOfferData[index]['amount'] = offerproduct.amount;
                  }
                })
            }) 
            setOfferData(newOfferData);
            setActivePage(1);
          }

          axios.get(process.env.REACT_APP_URL + 'getData.php?user=' + response.data[0].owner_id)
          .then(response =>  {
            //console.log(response)
            setClientData(response.data[0]);
          })
          .catch(error => console.log(error));
        }
      })
      .catch(error => console.log(error)); 
    }else {
      //load current User
      axios.get(process.env.REACT_APP_URL + 'getData.php?user=' + user.id)
      .then(response =>  {
        setClientData(response.data[0]);
        setOfferOverview({});
        setOfferData({});
        setActivePage(0);
        setCurFilter(1);

        if(newFromFrontend) {
          let products = newFromFrontend.split(";");
          let newOfferData = {};
    
          global.config.productsdata.map(function(value, index) {
            products.map(function(offerproduct, idx) {
              if(value.anr == offerproduct) {
                newOfferData[index] = value;
                newOfferData[index]['amount'] = 1;
              }
            })
          }) 
          setOfferData(newOfferData);
          setActivePage(1);
        }

      })
      .catch(error => console.log(error)); 
    }
    //if new load Productgroup
      axios.get(process.env.REACT_APP_URL + 'getData.php?productgroup=true')
      .then(response =>  {
        setProductGroups(response.data);
      }).catch(error => console.log(error)); 

  }, [newItem, productData]);

  function saveData(event) {
    event.preventDefault();
    setLoading({'display': true, 'circles': true, 'dialog': false, 'error' : false});
    console.log(offerData, user);

    let url= process.env.REACT_APP_URL +'setOffer.php?user='+ user.id;

    if(offerid) url= process.env.REACT_APP_URL +'setOffer.php?user='+ user.id + '&offerid='+offerid;

    console.log(url , offerData, user);

    axios.post(url, {offerdata : offerData, userdata : user})
      .then(response =>  {
        console.log(response);
        /*if(!offerid && response.data[0] != true) {
          window.location.href="/offers/detail?id=" + response.data[1];
        }else {*/
          if(!offerid && response.data[0] == true) setOfferid(response.data[1]);
          setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : false});
        //}

         //if Userdata where changed in Offer
         if(changedUser) {
          axios.post(process.env.REACT_APP_URL + 'setData.php?user=true', {...user, ...data})
          .then(response =>  {
            console.log(response);
          })
          .catch(error => console.log(error));
        }

      })
      .catch(error => console.log(error));
  }

  function handleSubmit (event, type) {
    event.preventDefault();
    
    const isFormValid = formRef.current.checkValidity();
    console.log(event.target, type, offerData, user);

    console.log(data);

    if (isFormValid && activePage == 2) 
      setActivePage(activePage + 1)
    else if (isFormValid && activePage == 3) {
      setLoading({'display': true, 'circles': true, 'dialog': false, 'error' : false});
      
      let url= process.env.REACT_APP_URL +'setOffer.php?user='+ user.id+'&ordered=1';
      if(offerid) url= process.env.REACT_APP_URL +'setOffer.php?user='+ user.id + '&offerid='+offerid+'&ordered=1';

      console.log(url , offerData, user);

      axios.post(url, {offerdata : offerData, userdata : user})
        .then(response =>  {
          console.log(response);
          if(!offerid && response.data[0] == true) window.location.href="/offers/offer?offer=" + response.data[1];
          else setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : false});
          
          //if Userdata where changed in Offer
          if(changedUser) {
            axios.post(process.env.REACT_APP_URL + 'setData.php?user=true', data)
            .then(response =>  {
              localStorage.setItem('kerion_user', JSON.stringify({...user, data}));
              console.log(response);
            })
            .catch(error => console.log(error));
          }

        })
        .catch(error => console.log(error));
      } else {
        console.log('Form is not valid. Please check your inputs.');
      }
  }

  function handleChange(key, value, array)  {
    setChangedUser(true);
    setData({...data, [key]: value});
    setClientData({...clientData, [key]: value});
  }


  function changeAmount(e, value, index) {
    let newOfferdata = {[index] : {...offerData[index], amount : e.target.value}};
    setOfferData({...offerData, ...newOfferdata});
  }

  function chooseCategory(category) {
      setCurFilter(curFilter + 1);
      setChosenProduct({'category' : category});

      axios.get(process.env.REACT_APP_URL + 'getData.php?productgroup='+category.id+'&productvariations=true')
      .then(response =>  {
        setProductVariations(response.data);
      });
  }

  function chooseVariation(variation) {
    setCurFilter(curFilter + 1);
    setChosenProduct({...chosenProduct, 'variation' : variation});
    setSelectionData([]);

    axios.get(process.env.REACT_APP_URL + 'getData.php?variationid='+variation.id)
    .then(response =>  {
      console.log(response);
      setSelectionData(response.data);
    });
  }

  function addToOffer(index) {  
    let curOfferData = {...offerData};

    console.log(index, curOfferData);

    if(offerData[index]){
      delete curOfferData[index];
      setOfferData(curOfferData);
    }else {
      let newOfferData = {[index] : {...productData[index], amount : 1}};
      setOfferData({...offerData, ...newOfferData});
    } 
    
  }

  function removeOfferItem(index) {
      let offer = {...offerData};
      delete offer[index];
      setOfferData(offer);
  }

  function handleOk() {
      setLoading({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  }

  function changeStatus(e = '', status = 'new') {
    if(e && e.target.checked || status == 'done') {
      status = 'done';
      setOfferStatus(true);
    }else {
      setOfferStatus(false);
    }

    axios.post(process.env.REACT_APP_URL + 'setOffer.php?offerid='+offerOverview.offernumber, {'status' : status})
    .then(response =>  {
      setOfferOverview({...offerOverview, status : status});
    });
  }


  return (
    <div className="main offerdetail">
        <Loading
            loading={loading}         
            headline={{'success' : global.config.successheadline[language], 'error' : global.config.errorheadline[language]}}
            text={{'success' :global.config.offersuccesstext[language], 'error' : global.config.offererrortext[language]}}
            button={{'ok' : true}}
            handleOk={handleOk} 
        />
        <Sidemenu/>
        {itemDeleted &&
          <section className='errorcontainer'>
            <h4>{global.config.offerdeleted[language]}</h4>
            <p>{global.config.createnewofferorback[language]}</p>
            <div className="buttoncontainer">
              <a className='button' href="/offers"><i className='fas fa-chevron-left'></i>{global.config.back[language]}</a>
              <a className='button' href="/offers/offer?new=true"><i className='fas fa-plus'></i>{global.config.createnewoffer[language]}</a>
            </div>
          </section>
        }
        <section>
        {(!newItem && offerOverview['ordered'] == 1 && Object.keys(offerData).length > 0) &&
            <>
              {clientData && user && (clientData.id == user.id) && 
                <>
                  <h1>{global.config.viewmyoffer[language]}</h1>
                  <p style={{textAlign: 'center', maxWidth: 700, margin: '0 auto', padding: '20px 0'}}>
                    {global.config.offersent[language]}
                    </p>
                </>
              }
              {clientData && user && (clientData.id != user.id) &&
                <>
                  <h1>{global.config.offerfrom[language]} {clientData['fullname']}</h1>
                
                  <div className="offerstate">
                      <div className="status"><div className={" " + offerOverview['status']}></div></div>
                      <h4>{global.config.offerstatus[language]}: </h4>
                      <div className='checkboxcontainer'>
                          <input type="checkbox"
                            id="status"
                            name="status" 
                            onChange={(e) => changeStatus(e)} 
                            defaultChecked={offerStatus} />
                          <label htmlFor='status'> erledigt</label>
                      </div>
                  </div>
                </>
              }
              
              <OfferPreview offerData={offerData} clientData={clientData} />

              {user.roles.indexOf('ADMIN') > -1 && 
                <div className='table-buttons'>
                  <a href={"/offers"} className="button"><i className='fas fa-chevron-left'></i><span>{global.config.back[language]}</span></a>
                  <button className="secondbutton" type="button" onClick={() => {document.getElementById('status').click()}}>
                    
                      {offerStatus &&  <><i className='fas fa-xmark'></i><span>{global.config.markasnew[language]}</span></>}
                      {!offerStatus &&  <><i className='fas fa-check'></i><span> {global.config.markasdone[language]} </span></>}
                     
                    
                  </button>
                  
                </div>     
              }
            </>
        }
        {(offerOverview['ordered'] == 0 || newItem) && 
          <>
          
          {newItem && <h1>{global.config.createnewoffer[language]}</h1>}
          {!newItem && (clientData.id != user.id) &&
            <h1>{global.config.viewofferfrom[language]} {clientData['fullname']}</h1>
          }
          {!newItem && (clientData.id == user.id) && <h1>{global.config.editoffer[language]}</h1>}

          <>
            <div className='timeline'>
                <div className={'clickable ' + ((activePage == 0) ? 'active' : '')}
                  onClick={() => setActivePage(0)}><i>1</i>{global.config.addproducts[language]}</div>
                <div className={'clickable ' + ((activePage == 1) ? 'active' : '')}
                  onClick={() => setActivePage(1)}><i>2</i>{global.config.defineamount[language]} <span id="productinoffer">({Object.keys(offerData).length} {global.config.products[language]})</span></div>
                <div className={'clickable ' + ((activePage == 2) ? 'active' : '')}
                  onClick={() => setActivePage(2)}><i>3</i>{global.config.checkletterhead[language]}</div>
                <div className={((activePage == 3) ? 'active' : '')}><i>4</i>{global.config.createpreview[language]}</div>
            </div>
          

          <form ref={formRef} onSubmit={handleSubmit} className="offerdetailform">         
            {activePage == 0 &&
                <div className='offertable-container'>
                    <div className="">
                      {curFilter == 1 &&
                        <>
                        <div className='product_back_container'>
                          <h4>{global.config.productgroup[language]}</h4>
                        </div>
                          <div className="row">
                            {productGroups &&
                              productGroups.map(function(val,index) { 
                                  return (
                                    <div key={'group-' + index} className="col-4 product_category" onClick={() => chooseCategory(val)}>
                                      <img src={process.env.REACT_APP_IMAGE_URL + val.image} />
                                      <h4 className="product_title">{language == 'de' ? val.name : val.name_en}</h4> 
                                      <span></span>
                                    </div>
                                  )
                              })                          
                            }
                          </div>
                        </>
                      }
                      {curFilter == 2 && 
                        <>
                         <div className='product_back_container'>
                          <button onClick={() => setCurFilter(curFilter - 1)}><i className='fas fa-chevron-left'></i>{global.config.productgroup[language]} /</button> 
                          <h4>{chosenProduct.category.name}</h4>
                         </div>
                         <div className="row">
                          {productVariations &&
                              productVariations.map(function(val,index) { 
                                  return (
                                    <div key={'variation-' + index} className="col-4 product_category" onClick={() => chooseVariation(val)}>
                                      <img src={process.env.REACT_APP_IMAGE_URL + val.image} />
                                      <h4 className="product_title">{val.product_variation_name}</h4> 
                                      <span></span>
                                    </div>
                                  )
                              })                          
                            }
                          </div>
                        </>
                      }
                      {curFilter == 3 && 
                        <>
                         <div className='product_back_container'>
                          <div onClick={() => setCurFilter(curFilter - 1)}><i className='fas fa-chevron-left'></i></div> 
                          <button onClick={() => setCurFilter(curFilter - 2)}>{global.config.productgroup[language]} /</button> 
                          <button onClick={() => setCurFilter(curFilter - 1)}>{chosenProduct.category.name} / </button> 
                          <h4><span className='highlight'>{chosenProduct.variation.product_variation_name}</span></h4>
                         </div>
                         <div className="row">
                            
                              {selectionData && selectionData.length &&
                                <EasyTable variation={chosenProduct.variation} offerdata={offerData} data={selectionData} addToOffer={(i) => addToOffer(i)}/>
                              }
     
                          </div>
                        </>
                      }
                    </div>
                </div>
            }{activePage == 1 && Object.keys(offerData).length > 0 &&
              <>
              <div></div>
                <table className='offertable'>
                  <thead>
                    <tr className='offerlist'>
                        <th>Löschen</th>
                        <th>{global.config.anr[language]}</th>
                        <th>{global.config.variationimage[language]} </th>
                        <th>{global.config.variationperformance[language]}</th>
                        <th>{global.config.productperformance[language]}</th>
                        <th>{global.config.amount[language]}</th>
                        <th>{global.config.performance_price[language]}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    Object.keys(offerData).map((value, index) => {
                        let val = offerData[value];
                        console.log(value);

                        return (
                            <tr className='offerlist' key={index}>
                                <td><i className="fas fa-trash" onClick={() => removeOfferItem(value)}></i></td>
                                <td>{val.anr}<br/><b>{val.product_variation_name}</b></td>
                                <td><img width="200px" src={process.env.REACT_APP_IMAGE_URL + val.image} /></td>
                                <td>
                                  <div><span>{global.config.color[language]} :</span> <span>{val.colorname}</span></div>
                                  <div><span>{global.config.optic[language]} :</span> <span>{val.opticname}</span></div>
                                  <div><span>{global.config.dimmable[language]} :</span> <span>{val.dimmablename}</span></div>
                                </td>
                                <td>
                                  <div><span>{global.config.lighttemperature[language]} :</span> <span>{val.lighttemperaturename}</span></div>
                                  <div><span>{global.config.cri[language]} :</span> <span>{val.criname}</span></div>
                                  <div><span>{global.config.watt[language]} :</span> <span>{val.watt}</span></div>
                                  <div><span>{global.config.lumen[language]} :</span> <span>{val.lumen}</span></div>
                                  <div><span>{global.config.masse[language]} :</span> <span>{val.length_mm} x {val.height_mm} x {val.width_mm}</span></div>
                                  <div><span>{global.config.diameter_mm[language]} :</span> <span>{val.diameter_mm}</span></div>
                                  <div><span>{global.config.weight[language]} :</span> <span>{val.weight}</span></div>
                                  <div><span>{global.config.outline[language]} :</span> <span>{val.outline}</span></div>
                                  {val.woodoptionname && <div><span>{global.config.woodoption[language]} :</span> <span>{val.woodoptionname}</span></div>}
                                  {val.woodsurfacename && <div><span>{global.config.woodsurface[language]} :</span> <span>{val.woodsurfacename}</span></div>}
                                </td>
                                <td><input name="amount" type="number" defaultValue={val.amount} onChange={(e) => changeAmount(e, val, val.id - 1)}/></td>
                                <td>
                                 
                                  {user.is_verified == 1 && (val.price != null && val.price != 0 ) && Number(val.price * val.amount).toFixed(2)}
                                  {user.is_verified == 1 && (!val.price && val.oldprice != null) && Number(val.oldprice * val.amount).toFixed(2)}

                                  {/*USER NOT VERIFIED - +UST */}
                                  {user.is_verified == 0 && (val.price != null && val.price != 0 ) && Number(val.price * val.amount * 1.2 ).toFixed(2)}
                                  {user.is_verified == 0 && ((!val.price && val.oldprice != null) && Number(val.oldprice * val.amount * 1.2).toFixed(2))}
                                   €
                                </td>
                            </tr>
                        )
                    })
                  }
                  </tbody>
                </table>
              </>
            } {activePage == 1 && Object.keys(offerData).length == 0 &&

              <div style={{textAlign : 'center'}}>
                <h3>{global.config.noproductselected[language]}</h3>
              </div>
            }

            
            {activePage == 2 && 
                <div className="offerdata">
                  <div></div>
                  <FormItems elements={Elements} handleChange={handleChange} data={clientData} type={newItem ? 'new' : 'detail'} />
                </div>
            }

            {activePage == 3 &&  
                <OfferPreview offerData={offerData} clientData={clientData} />
            }
            
            
            <div className='table-buttons' style={{marginTop: '40px'}}>
              {activePage == 0 && <a className="button" href={"/offers"} ><i className="fa-solid fa-table"></i>{global.config.backtotable[language]}</a> }
              {activePage != 0 && <div className="button" onClick={() => setActivePage(activePage - 1)} ><i className='fas fa-chevron-left'></i>{global.config.back[language]}</div>}
              
              {Object.keys(offerData).length > 0 && (clientData.id == user.id) && 
                <button type="submit" className="secondstyle" onClick={(e) => saveData(e)} >{global.config.save[language]}<i className='fas fa-save'></i></button>
              }
              
              {activePage == 2 && <button className="button" type="submit" >{global.config.next[language]}<i className='fas fa-chevron-right'></i></button>}
              {activePage < 2 && <div className="button" onClick={() => setActivePage(activePage + 1)} >{global.config.next[language]}<i className='fas fa-chevron-right'></i></div>}

              {activePage == 3 && Object.keys(offerData).length > 0 &&
                  <div className="flex"> 
                    <button type="submit" className="secondstyle" >{global.config.sharetoediting[language]}<i className='fas fa-paper-plane'></i></button>
                </div>
              }
            </div>
          </form>
          </>
            
          </>
        }
        </section>

    </div>
  );
}

export default OfferDetail;