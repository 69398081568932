import { Select, MenuItem , NativeSelect, FormControl, InputLabel } from "@mui/material";
import { useState, useEffect } from "react";
import axios from 'axios';

function MySelect(props) {
  const [val, setVal] = useState(props.data[props.config.field]);
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    setVal(props.data[props.config.field]);
  }, [props.data]);
  

  function changeVal(e) {
    setVal(e.target.value);
    props.onChange(props.config.field, e.target.value);
  }

  useEffect(() => {  
    
    console.log(props.config.type);

    if(props.config.type != 'countryselect') {
      axios.get(process.env.REACT_APP_URL + 'getSelectData.php?data='+props.config.databasetable)
      .then(response =>  {
        setSelectOptions(response.data);
      })
      .catch(error => console.log(error));
    } else {
      setSelectOptions([
        {id: 'aut', name : 'Österreich', name_en : 'Austria'}, 
        {id: 'de', name : 'Deutschland', name_en : 'Germany'}, 
        {id: 'dnk', name : 'Dänemark', name_en : 'Denmark'},
        {id: 'fra', name : 'Frankreich', name_en : 'France'},
        {id: 'ita', name : 'Italien', name_en : 'Italy'},
        {id: 'hrv', name : 'Kroatien', name_en : 'Croatia'},
        {id: 'nld', name : 'Niederlande', name_en : 'Netherland'},
        {id: 'prt', name : 'Portugal', name_en : 'Portugal'},
        {id: 'rou', name : 'Rumänien', name_en : 'Romania'},
        {id: 'tur', name : 'Türkei', name_en : 'Türkiye'},
        {id: 'swe', name : 'Schweden', name_en : 'Sweden'},
        {id: 'ch', name : 'Schweiz', name_en : 'Switzerland'}]);
    }
  }, []);

  return (
    <label className="labelcontainer"><span>{props.config.label}</span>
            
            <select 
            type={props.config.type}
            value={val}
            autoWidth
            name={props.config.field}
            onChange={changeVal}
            required={props.config.required}
            readOnly={props.config.readonly}
            >
                {selectOptions && 
                  selectOptions.map(function(value, index){
                    return <option key={index} value={value.id ? value.id : value.id}>{value.name ? value.name : value.category_name}</option>;
                })
                }

        </select>
    </label>
        
  );
}


export default MySelect;