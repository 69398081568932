
function Headline(props) {

  return (
    <>
        <h4 className="form-headline">
            {props.config.label}
        </h4>
    </>
        
  );
}


export default Headline;