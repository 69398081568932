import { useState, useEffect } from "react";
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

function Checkbox(props) {
  const [val, setVal] = useState(props.data[props.config.field] ? props.data[props.config.field] : '');
  const [checkboxVal, setCheckboxVal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  let variationid = searchParams.get("detail");
  const [visible, setVisible] = useState(true);

  let user = JSON.parse(localStorage.getItem('kerion_user'));


  useEffect(() => { 

    /*if(props.variationdata)
    console.log(props.config.field, props.variationdata['lightoutput'])*/

    if((props.variationdata && props.config.field == 'ugr_indirect' && props.variationdata['lightoutput'] == 1) ||
      (props.variationdata && props.config.field == 'ugr' && props.variationdata['lightoutput'] == 2) ) {
        setVisible(false);
    }else {
        setVisible(true);
    }
  },[props.variationdata]);


  function changeVal(e, single = false) {
      let newValues = {...val};
      newValues[e.target.value] = e.target.checked;

      setVal(newValues);
      if(!single)
        props.onChange('cross_product_variation_' + props.config.field, newValues);
      else
        props.onChange(props.config.field, (e.target.checked ? 1 : 0));
  }

  useEffect(() => { 
    if(props.config.databasetable) {
      axios.get(process.env.REACT_APP_URL + 'getSelectData.php?data='+props.config.databasetable)
      .then(response =>  {
        setCheckboxVal(response.data);
      })
      .catch(error => console.log(error));

    }   
    if(variationid) {
        axios.get(process.env.REACT_APP_URL + 'getSelectData.php?variation='+variationid+'&data='+props.config.databaseVariationTable)
        .then(response =>  {
            let values = {};
          for(let i = 0; i < response.data.length; i++) {
                values[response.data[i][props.config.field + '_id']] = true;
          }

          setVal(values);
        })
        .catch(error => console.log(error));
      }      
    
  }, []);

  return (
    <> 
    {visible && (props.config.onlyadmin != true || user.roles.indexOf('ADMIN') >= 0) && 
    <div><label className={'labelcontainer type-' + props.config.type}>
        <span>{props.config.label}</span>
        <div className="inputcontainer">
        
        {props.config.type == 'checkbox' && checkboxVal &&
          checkboxVal.map(function( key ,index) {
            let checked=false;            
            if(props.data['cross_product_variation_' +props.config.field] && props.data['cross_product_variation_' + props.config.field][key.id]) checked=true;
        
            return (
              <div className="checkboxcontainer" key={index}>
                <input 
                  type={props.config.type}
                  placeholder={props.data[props.config.field]}
                  readOnly={props.config.readonly}
                  value={key.id}
                  name={props.config.field}
                  required={props.config.required}
                  onChange={(e) => changeVal(e, '', true)}
                  defaultChecked={checked}
                  id={props.config.field + '_' + key.id }
                  
                /> <label htmlFor={props.config.field + '_' + key.id }>
                  {key.name}
              </label>
             </div>)
          })
        } {props.config.type == 'checkbox' && !checkboxVal &&
          
              <div className="checkboxcontainer">
                <input 
                  type={props.config.type}
                  placeholder={props.data[props.config.field]}
                  readOnly={props.config.readonly}
                  name={props.config.field}
                  required={props.config.required}
                  onChange={changeVal}
                  defaultChecked={val}
                  id={props.config.field }
                /> <label htmlFor={props.config.field }>
                  
              </label>
            </div>
          }{props.config.type == 'singlecheckbox' && 
              
            <div className="checkboxcontainer">
              <input 
                type='checkbox'
                placeholder={props.data[props.config.field]}
                readOnly={props.config.readonly}
                name={props.config.field}
                required={props.config.required}
                onChange={(e) => {changeVal(e, true)}}
                defaultChecked={val}
                id={props.config.field }
              /> <label htmlFor={props.config.field }>
                
            </label>
          </div>
          }
        </div>
    </label>
    </div>    
    }
  </>
  )
}


export default Checkbox;