import { NavLink } from "react-router-dom";

export const columns = [
    {
        headerName: "ID",
        field: "id",
        width: 100,
        renderCell: (params) => {
            return <NavLink className={"statistic-table-link"} to={`/productvariations/detail?detail=${params.row['id']}`} title={params.value}>{params.value}</NavLink>
        }
    },
    {
        headerName: 'Variations Name',
        field: 'product_variation_name',
        width: 200,
        renderCell: (params) => {
            return <NavLink className={"statistic-table-link"} to={`/productvariations/detail?detail=${params.row['id']}`} title={params.value}>{params.value}</NavLink>
        }
    },
    {
        headerName: 'Variations Bild',
        field: 'image',
        width: 160,
        renderCell: (params) => {
            return <img className={"statistic-table-link"} width="100px" src={process.env.REACT_APP_IMAGE_URL + params.value} />
        }
    },
    {
        headerName: 'Produktkategorie',
        field: 'categoryname',
        width: 200,
    },
    {
        headerName: 'Aktionen',
        flex: 2,
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NavLink className={"table-link"} to={`/productvariations/detail?detail=${params.row['id']}`} title={params.value}><i className="fa-solid fa-pencil"></i> Bearbeiten </NavLink>
                </div>
            )
        }
    },
]

export const columns_en = [
    {
        headerName: "ID",
        field: "id",
        width: 100,
        renderCell: (params) => {
            return <NavLink className={"statistic-table-link"} to={`/productvariations/detail?detail=${params.row['id']}`} title={params.value}>{params.value}</NavLink>
        }
    },
    {
        headerName: 'Variation Name',
        field: 'product_variation_name',
        width: 200,
        renderCell: (params) => {
            return <NavLink className={"statistic-table-link"} to={`/productvariations/detail?detail=${params.row['id']}`} title={params.value}>{params.value}</NavLink>
        }
    },
    {
        headerName: 'Variation Image',
        field: 'image',
        width: 160,
        renderCell: (params) => {
            return <img className={"statistic-table-link"} width="100px" src={process.env.REACT_APP_IMAGE_URL + params.value} />
        }
    },
    {
        headerName: 'Productcategory',
        field: 'categoryname',
        width: 200,
    },
    {
        headerName: 'Actions',
        flex: 2,
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NavLink className={"table-link"} to={`/productvariations/detail?detail=${params.row['id']}`} title={params.value}><i className="fa-solid fa-pencil"></i> Bearbeiten </NavLink>
                </div>
            )
        }
    },
]

