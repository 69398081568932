import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../Css/main.css';
import Sidemenu from '../Sidemenu';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import FormItems from './FormItems';
import {Cri, Color, Optic, Lighttemperature, Dimmable, Woodoption, Woodsurface, Category, Ugr} from './ProductCharacterDetailConfig';
import Loading from '../Helper/Loading';

export const modes = {
  "cri": Cri,
  "color": Color,
  "optic": Optic,
  "ugr": Ugr,
  "lighttemperature": Lighttemperature,
  "dimmable": Dimmable,
  "woodoption": Woodoption,
  "woodsurface": Woodsurface,
  "category": Category,
}


function ProductCharacterDetail() {
  let { character } = useParams();
  const [data, setData] = useState([]);
  const [files, setFiles] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  let characterid = searchParams.get("detail");
  let newItem = searchParams.get("new") ? JSON.parse(searchParams.get("new")) : false;

  const formRef = useRef(null);
  const Elements = modes[character];
  
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  let language = localStorage.getItem("kerion_language");

  if(!localStorage.getItem('kerion_user')) {
    window.location.href = '/login';
  }
  
  useEffect(() => {
    if(!character != 'new') {
      axios.get(process.env.REACT_APP_URL + 'getData.php?character='+character+'&detail='+characterid)
      .then(response =>  {
        console.log(response)
        setData(response.data[0]);
      })
      .catch(error => console.log(error));
    }else {
      console.log('new');
    }
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();
    const isFormValid = formRef.current.checkValidity();
    console.log(data, files);

    if (isFormValid) {
      let url = process.env.REACT_APP_URL + 'setData.php?character='+character;
      if(!newItem) url = process.env.REACT_APP_URL + 'setData.php?character='+character+'&characterid='+characterid;

      axios.post(url, data).then(response =>  {
        console.log(response, Object.keys(files).length );

        //IF IMAGES WHERE SET
        if(Object.keys(files).length > 0) {

            if(newItem) characterid = response[1];
            let url = process.env.REACT_APP_URL + 'setData.php?character='+character+'&characterid='+characterid;
            const formData = new FormData();

            Object.keys(files).map( (key, value) => {
                formData.append(key, files[key]);
            })

            const setFiles = async(url) =>{
              return await axios.post(url, formData
              ).then(response =>  {
                  console.log(response.data);
                  
                  if(response.data[0] == true) 
                    setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : false});
                  else
                    setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : true});

                  return response.data;
              })
            }

            let success = setFiles(url);
        
        }else if(response.data != null) {
          window.location.href="/productcharacteristics/" + character;
        }
      })
      .catch(error => console.log(error));
    } else {
      console.log('Form is not valid. Please check your inputs.');
    }
  }

  function handleChange(key, value, type = '')  {
    if(type == 'files') setFiles({...files, [key]: value});
    else setData({...data, [key]: value});
  }

  function handleOk() {
    window.location.reload();
  }

  return (
    <div className="main">
        <Loading
            loading={loading}         
            headline={{'success' : global.config.successheadline[language], 'error' : global.config.errorheadline[language]}}
            text={{'success' :global.config.productvsuccesstext[language], 'error' : global.config.productverrortext[language]}}
            button={{'ok' : true}}
            handleOk={handleOk} 
        />
        <Sidemenu/>
        <section>
          <h1>Productcharacter {character}</h1>
          <form ref={formRef} onSubmit={handleSubmit}>
            <FormItems elements={Elements} handleChange={handleChange} data={data} type={newItem ? 'new' : 'detail'} />
            <div className='table-buttons'>
              <button href={"/productcharacteristics/" + character} >Zurück zur Tabelle</button>
              <button className="secondstyle" type="submit">Speichern</button>
            </div>
          </form>
        </section>

    </div>
  );
}

export default ProductCharacterDetail;