export const Elements = [
    {
        label: 'Name',
        label_en: 'Name*',
        field: 'fullname',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true
    },
    {
        label: 'Email',
        label_en: 'Email*',
        field: 'email',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true,
        readonly: true
    },
    {
        label: 'Telefon',
        field: 'phone',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true,
    },
    {
        label: 'Verifziert',
        field: 'is_verified',
        htmlfield: "checkbox",
        type: 'singlecheckbox',
        width: 200,
        onlyadmin : true
    },
    {
        label: 'Aktiv',
        field: 'is_active',
        htmlfield: "checkbox",
        type: 'singlecheckbox',
        width: 200,
        onlyadmin : true
    },
    {
        label: 'Email verifziert',
        field: 'email_verified',
        htmlfield: "checkbox",
        type: 'singlecheckbox',
        width: 200,
        onlyadmin : true
    },
    {
        label: 'Newsletter',
        field: 'newsletter',
        htmlfield: 'checkbox',
        type: 'singlecheckbox',
    },
    {
        label: 'Adresse',
        htmlfield: "headline",
        type: 'h4',
        width: 200,
        required: true
    },
    {
        label: 'Straße',
        field: 'street',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true
    },
    {
        label: 'Hausnummer',
        field: 'housenumber',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true
    },
    {
        label: 'Stiege',
        field: 'floor',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Top',
        field: 'top',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'PLZ',
        field: 'zip',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true
    },
    {
        label: 'Ort',
        field: 'city',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true
    },
    {
        label: 'Land',
        field: 'country',
        htmlfield: "select",
        type: 'countryselect',
        width: 200,
    },
    /*{
        label: 'Bank',
        htmlfield: "headline",
        type: 'h4',
        width: 200,
    },
    {
        label: 'Bank',
        field: 'bank',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'IBAN',
        field: 'iban',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'BIC',
        field: 'bic',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },*/
    {
        label: 'Firmenbuchnummer',
        field: 'company_register_number',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'UID',
        field: 'tax_number',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Zusatzinfo',
        field: 'zk',
        htmlfield: "wysiwig",
        type: 'textarea',
        width: 200,
    },
    {
        label: 'Rabatt',
        field: 'discount',
        htmlfield: "input",
        type: 'text',
        width: 200,
        disabledforuser : true
    }
]

export const ElementsEn = [
    {
        label: 'Name',
        label_en: 'Name*',
        field: 'fullname',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true
    },
    {
        label: 'Email',
        label_en: 'Email*',
        field: 'email',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true,
        readonly: true
    },
    {
        label: 'Phone',
        field: 'phone',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true,
    },
    {
        label: 'Verified',
        field: 'is_verified',
        htmlfield: "checkbox",
        type: 'singlecheckbox',
        width: 200,
        onlyadmin : true
    },
    {
        label: 'Activ',
        field: 'is_active',
        htmlfield: "checkbox",
        type: 'singlecheckbox',
        width: 200,
        onlyadmin : true
    },
    {
        label: 'Email verified',
        field: 'email_verified',
        htmlfield: "checkbox",
        type: 'singlecheckbox',
        width: 200,
        onlyadmin : true
    },
    {
        label: 'Newsletter',
        field: 'newsletter',
        htmlfield: 'checkbox',
        type: 'singlecheckbox',
    },
    {
        label: 'Address',
        htmlfield: "headline",
        type: 'h4',
        width: 200,
        required: true
    },
    {
        label: 'Street',
        field: 'street',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true
    },
    {
        label: 'Housenumber',
        field: 'housenumber',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true
    },
    {
        label: 'Floor',
        field: 'floor',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Top',
        field: 'top',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'ZIP',
        field: 'zip',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true
    },
    {
        label: 'City',
        field: 'city',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true
    },
    {
        label: 'Country',
        field: 'country',
        htmlfield: "select",
        type: 'countryselect',
        width: 200,
    },
    /*{
        label: 'Bank',
        htmlfield: "headline",
        type: 'h4',
        width: 200,
    },
    {
        label: 'Bank',
        field: 'bank',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'IBAN',
        field: 'iban',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'BIC',
        field: 'bic',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },*/
    {
        label: 'Company register number',
        field: 'company_register_number',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'UID',
        field: 'tax_number',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Additional info',
        field: 'zk',
        htmlfield: "wysiwig",
        type: 'textarea',
        width: 200,
    },
    {
        label: 'Discount',
        field: 'discount',
        htmlfield: "input",
        type: 'text',
        width: 200,
        disabledforuser : true
    }
]