export const countryOptions = [
    {id: 'aut', name : 'Österreich', name_en : 'Austria'}, 
    {id: 'de', name : 'Deutschland', name_en : 'Germany'}, 
    {id: 'dnk', name : 'Dänemark', name_en : 'Denmark'},
    {id: 'fra', name : 'Frankreich', name_en : 'France'},
    {id: 'ita', name : 'Italien', name_en : 'Italy'},
    {id: 'hrv', name : 'Kroatien', name_en : 'Croatia'},
    {id: 'nld', name : 'Niederlande', name_en : 'Netherland'},
    {id: 'prt', name : 'Portugal', name_en : 'Portugal'},
    {id: 'rou', name : 'Rumänien', name_en : 'Romania'},
    {id: 'tur', name : 'Türkei', name_en : 'Türkiye'},
    {id: 'swe', name : 'Schweden', name_en : 'Sweden'},
    {id: 'ch', name : 'Schweiz', name_en : 'Switzerland'}
]