import { useState, useRef } from 'react';
import '../Css/main.css';
import Sidemenu from './Sidemenu';
import axios from 'axios';
import Loading from './Helper/Loading';
import { deleteCookie } from './Helper/cookiefuntions';

function UploadPrices() {
    const [file, setFile] = useState('');
    const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});
    const formRef = useRef(null);

    let language = localStorage.getItem("kerion_language");

    if(!localStorage.getItem('kerion_user')) {
        localStorage.setItem('loggedIn', false);
        deleteCookie('kerion_login');
        window.location.href = '/login';
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(event.target);
        const isFormValid = formRef.current.checkValidity();
        setLoading({...loading, 'display': true, 'circles': true});
        //console.log(formData);
        //let data = formData;
    
        if (isFormValid) {
            const formData = new FormData();
            formData.append('file', file);

            const setFiles = async(url) =>{
                return await axios.post(url, formData
                ).then(response =>  {
                    console.log(response)
                    if(response.data[0] == true) 
                        setLoading({...loading, 'display': true, 'circles': false, 'dialog': true});
                    else 
                        setLoading({...loading, 'display': true, 'circles': false, 'dialog': true, 'error' : true});
                    
                    return response.data;
                })
            }
            
            setFiles(process.env.REACT_APP_URL + 'setPrices.php?apikey=23234');
        } 
    }
    

    return (
        <div className='main'>
            <Loading
                loading={loading}         
                headline={{'success' :global.config.successheadline[language], 'error' : global.config.errorheadline[language]}}
                text={{'success' :global.config.pricesuccesstext[language], 'error' : global.config.priceerrortext[language]}}
                button={{'ok' : true}}
                handleOk={() => setLoading({'display': false, 'circles': false, 'dialog': false})} 
            />
            <Sidemenu />
            <section>
            <h1>{global.config.uploadprices[language]}</h1>
            <form className="uploadprices" ref={formRef} onSubmit={handleSubmit}>
                <input type="file" name="excel" onChange={(e) => setFile(e.target.files[0])}/>
                <button type="submit"><i className='fas fa-upload'></i>{global.config.upload[language]}</button>
            </form>
            </section>
        </div>
    );
}

export default UploadPrices;