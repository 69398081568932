import { NavLink } from "react-router-dom";

export const columns = [
    {
        headerName: "Name",
        field: "fullname",
        flex: 2,
        width: 300,
        minWidth: 300, 
        maxWidth: 250,
        renderCell: (params) => {
            return <NavLink className={"statistic-table-link"} to={`/users/user?user=${params.row['id']}`} title={params.value}>{params.value}</NavLink>
        }
    },
    {
        headerName: 'ID',
        field: 'id',
        width: 100,
    },
    {
        headerName: 'Email',
        field: 'email',
        width: 300,
    },
    {
        headerName: 'Aktiv',
        field: 'is_activ',
        width: 100,
        renderCell: (params) => {
            return <div className="checkboxcontainer"><input className="importantCheckbox" type="checkbox" defaultChecked={(params.row['is_verified'] == 1) ? 'checked' : ''} disabled /><label></label></div>;
        }
    },
    {
        headerName: 'Verifiziert',
        field: 'is_verified',
        width: 100,
        renderCell: (params) => {
            return <div className="checkboxcontainer"><input className="importantCheckbox" type="checkbox" defaultChecked={(params.row['is_verified'] == 1) ? 'checked' : ''} disabled /><label></label></div>;
        }
    },
    {
        headerName: 'Email verifiziert',
        field: 'email_verified',
        width: 200,
        renderCell: (params) => {
            return <div className="checkboxcontainer"><input type="checkbox" defaultChecked={(params.row['email_verified'] == 1) ? 'checked' : ''} disabled /><label></label></div>;
        }
    },
    {
        headerName: 'Telefon',
        field: 'phone',
        width: 200,
    },
    {
        headerName: 'Land',
        field: 'country',
        width: 150,
    },
    {
        headerName: 'Newsletter',
        field: 'newsletter',
        width: 100,
        renderCell: (params) => {
            return <div className="checkboxcontainer"><input type="checkbox" defaultChecked={(params.row['newsletter'] == 1) ? 'checked' : ''} disabled /><label></label></div>;
        }
    }
]
export const columns_en = [
    {
        headerName: "Name",
        field: "fullname",
        flex: 2,
        width: 300,
        minWidth: 300, 
        maxWidth: 250,
        renderCell: (params) => {
            return <NavLink className={"statistic-table-link"} to={`/users/user?user=${params.row['id']}`} title={params.value}>{params.value}</NavLink>
        }
    },
    {
        headerName: 'ID',
        field: 'id',
        width: 100,
    },
    {
        headerName: 'Email',
        field: 'email',
        width: 300,
    },
    {
        headerName: 'Activ',
        field: 'is_activ',
        width: 100,
        renderCell: (params) => {
            return <div className="checkboxcontainer"><input className="importantCheckbox" type="checkbox" defaultChecked={(params.row['is_verified'] == 1) ? 'checked' : ''} disabled /><label></label></div>;
        }
    },
    {
        headerName: 'Verified',
        field: 'is_verified',
        width: 100,
        renderCell: (params) => {
            return <div className="checkboxcontainer"><input className="importantCheckbox" type="checkbox" defaultChecked={(params.row['is_verified'] == 1) ? 'checked' : ''} disabled /><label></label></div>;
        }
    },
    {
        headerName: 'Email verified',
        field: 'email_verified',
        width: 200,
        renderCell: (params) => {
            return <div className="checkboxcontainer"><input type="checkbox" defaultChecked={(params.row['email_verified'] == 1) ? 'checked' : ''} disabled /><label></label></div>;
        }
    },
    {
        headerName: 'Phone',
        field: 'phone',
        width: 200,
    },
    {
        headerName: 'Country',
        field: 'country',
        width: 150,
    },
    {
        headerName: 'Newsletter',
        field: 'newsletter',
        width: 100,
        renderCell: (params) => {
            return <div className="checkboxcontainer"><input type="checkbox" defaultChecked={(params.row['newsletter'] == 1) ? 'checked' : ''} disabled /><label></label></div>;
        }
    }
]
