import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const workSheetName = 'Worksheet-1';
const fileName = "export.xlsx";

export async function exportExcel(data, columns) {

    const workbook = new Excel.Workbook();

    try {
        const worksheet = workbook.addWorksheet(workSheetName);

        worksheet.columns = columns;
        worksheet.getRow(1).font = { bold: true };  
        worksheet.columns.forEach(column => {
          column.width = column.header.length + 5;
          column.alignment = { horizontal: 'center' };
        });
  
        data.forEach(singleData => {
          worksheet.addRow(singleData);
        });
    
        const buf = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buf]), fileName);
      } catch (error) {
        console.error('<<<ERRROR>>>', error);
        console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
}

export async function exportExcelData(data, columns) {

  const workbook = new Excel.Workbook();

  try {
      const worksheet = workbook.addWorksheet(workSheetName);

      worksheet.columns = columns;
      worksheet.getRow(1).font = { bold: true };  
      worksheet.columns.forEach(column => {
        column.width = column.header.length + 5;
        column.alignment = { horizontal: 'center' };
      });

      data.forEach(singleData => {

        singleData.properties =  singleData.colorname != null ? 'Farbe: ' + singleData.colorname : '';
        singleData.properties += singleData.opticname != null ? ', Optik: ' + singleData.opticname: '' ;
        singleData.properties += singleData.dimmablename != null ? ', Dimmbar: ' +  singleData.dimmablename: '' ; 
        singleData.properties += singleData.lighttemperaturename != null ? ', Lichttemperatur: ' +  singleData.lighttemperaturename : '' ;
        singleData.properties += singleData.criname != null ? ', Cri: ' + singleData.criname : '' ;
        singleData.properties += singleData.watt != null ? ', Watt: ' +  singleData.watt : '' ;
        singleData.properties += singleData.lumen != null ? ', Lumen: ' +  singleData.lumen : '' ;
        singleData.properties += singleData.lumen_indirect != null ? ', Lumen indirekt: ' +  singleData.lumen_indirect : '' ;
        singleData.properties += singleData.power_supply != null ? ', Anschluss: ' +  singleData.power_supply : '' ;        
        singleData.properties += singleData.height_mm != null ? ', Höhe: ' +  singleData.height_mm : '' ;
        singleData.properties += singleData.length_mm != null ? ', Länge: ' +  singleData.length_mm : '' ;
        singleData.properties += singleData.width_mm != null ? ', Breite: ' +  singleData.width_mm : '' ;
        singleData.properties += singleData.diameter_mm  != null ? ', Durchmesser: ' + singleData.diameter_mm: '' ;
        singleData.properties += singleData.weight  != null ?', Gewicht: ' + singleData.weight: '' ;                 
        singleData.properties += singleData.outline  != null ?', Deckenausschnitt: ' + singleData.outline: '' ;
        singleData.properties += singleData.ambient_temperature  != null ? ', max. Umgebungstemperatur: ' + singleData.ambient_temperature: '' ;
        singleData.properties += singleData.lifespan != null ? ', Lebensdauer: ' + singleData.lifespan: '' ;
        singleData.properties += singleData.protectionclass  != null ?', Schutzklasse: ' + singleData.protectionclass : '' ;
        singleData.properties += singleData.protectiontype_ip  != null ? ', Schutzart: ' + singleData.protectiontype_ip : '' ;
        singleData.properties += (singleData.radiation  != null && singleData.radiation  != '') ?', Abstrahlung: ' + singleData.radiation  : '' ;
        singleData.properties += singleData.ugrname  != null ?', Abdeckung: ' + singleData.ugrname  : '' ;
        singleData.properties += singleData.ugr_indirect != null ?', UGR: ' + singleData.ugr_indirect  : '' ;
        singleData.properties += singleData.woodoptionname != null ?', Holzoption: ' + singleData.woodoptionname : '' ;
        singleData.properties += singleData.woodsurfacename != null ?  ', Holzooberfläche: ' + singleData.woodsurfacename: '' ;

        singleData.properties_en =  singleData.colorname != null ? 'Color: ' + singleData.colorname : '';
        singleData.properties_en += singleData.opticname != null ? ', Optic: ' + singleData.opticname: '' ;
        singleData.properties_en += singleData.dimmablename != null ? ', Dimmable: ' +  singleData.dimmablename: '' ; 
        singleData.properties_en += singleData.lighttemperaturename != null ? ', Lighttemperature: ' +  singleData.lighttemperaturename : '' ;
        singleData.properties_en += singleData.criname != null ? ', Cri: ' + singleData.criname : '' ;
        singleData.properties_en += singleData.watt != null ? ', Watt: ' +  singleData.watt : '' ;
        singleData.properties_en += singleData.lumen != null ? ', Lumen: ' +  singleData.lumen : '' ;
        singleData.properties_en += singleData.lumen_indirect != null ? ', Lumen indirekt: ' +  singleData.lumen_indirect : '' ;
        singleData.properties_en += singleData.power_supply != null ? ', Connection: ' +  singleData.power_supply : '' ;        
        singleData.properties_en += singleData.height_mm != null ? ', Height: ' +  singleData.height_mm : '' ;
        singleData.properties_en += singleData.length_mm != null ? ', Length: ' +  singleData.length_mm : '' ;
        singleData.properties_en += singleData.width_mm != null ? ', Width: ' +  singleData.width_mm : '' ;
        singleData.properties_en += singleData.diameter_mm  != null ? ', Diameter: ' + singleData.diameter_mm: '' ;
        singleData.properties_en += singleData.weight  != null ?', Weight: ' + singleData.weight: '' ;                 
        singleData.properties_en += singleData.outline  != null ?', Outline: ' + singleData.outline: '' ;
        singleData.properties_en += singleData.ambient_temperature  != null ? ', max. ambient temperature: ' + singleData.ambient_temperature: '' ;
        singleData.properties_en += singleData.lifespan != null ? ', Lifespan: ' + singleData.lifespan: '' ;
        singleData.properties_en += singleData.protectionclass  != null ?', Protection class: ' + singleData.protectionclass : '' ;
        singleData.properties_en += singleData.protectiontype_ip  != null ? ', Protection rating: ' + singleData.protectiontype_ip : '' ;
        singleData.properties_en += (singleData.radiation  != null && singleData.radiation  != '') ?', Radiation: ' + singleData.radiation  : '' ;
        singleData.properties_en += singleData.ugrname  != null ?', Cover: ' + singleData.ugrname  : '' ;
        singleData.properties_en += singleData.ugr_indirect != null ?', UGR: ' + singleData.ugr_indirect  : '' ;
        singleData.properties_en += singleData.woodoptionname != null ?', Woodoption: ' + singleData.woodoptionname : '' ;
        singleData.properties_en += singleData.woodsurfacename != null ?  ', Woodsurface: ' + singleData.woodsurfacename: '' ;

        singleData.description = stripHtml(singleData.description) ?? '';
        singleData.description_en = stripHtml(singleData.description_en) ?? '';   
        singleData.delivery_scope_de = stripHtml(singleData.delivery_scope_de) ?? '';
        singleData.delivery_scope_en = stripHtml(singleData.delivery_scope_en) ?? '';             

        singleData.datenblatt = {text:'Datenblatt', hyperlink: 'https://b2b.kerionlighting.com/pdfs/datasheet.php?anr='+singleData.anr};
        singleData.datenblatt_en = {text:'Datasheet', hyperlink: 'https://b2b.kerionlighting.com/pdfs/datasheet.php?anr='+singleData.anr + '&lang=en'};
        
        //console.log(singleData);
        worksheet.addRow(singleData);
      });
  
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), fileName);
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      workbook.removeWorksheet(workSheetName);
    }
}

function stripHtml(html)
{
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;

   return tmp.textContent || tmp.innerText || "";
}