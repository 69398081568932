import { useEffect, useState } from "react";
import RenderHTML from "../Elements/RenderHTML";
import Filter from "../Elements/Filter";
import axios from 'axios';
import MySlider from "../Elements/Slider";


function EasyTable(props) {
    const [searchValue, setSearchValue] = useState('');
    const [filter, setFilter] = useState({});
    const [slider, setSlider] = useState({});
    const [renderedFilter, setRenderedFilter] = useState();
    const [renderedSlider, setRenderedSlider] = useState({'watt': [], 'opticname': [], 'lumen' : []});
    const [curData, setCurData] = useState([...props.data]);
    const [productCount, setProductCount] = useState(props.data.length);

    let language = localStorage.getItem("kerion_language");

    function addFilter(e, filtername, idx) {
        let newFilter = {[idx] : e.target.value};

        if(filter[filtername]){
            let filtergroup = filter[filtername];

            if(filtergroup[idx])
                delete filtergroup[idx];
            else
                filtergroup = {...filtergroup, [idx] : e.target.value};

            setFilter({...filter, [filtername] : filtergroup}); 
        }
        else {
            setFilter({...filter, [filtername] : newFilter});
        } 
    }
    function addSliderFilter(values, slidername) {
        setSlider({...slider, [slidername] : values}); 
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL + 'getFilter.php?filter=true')
        .then(response =>  {
            setRenderedFilter(response.data[0]);

            let slider = {...renderedSlider}

            curData.map(function(value) {
                Object.keys(renderedSlider).map(function(key) {
                    if(!slider[key][0] || parseFloat(value[key]) < slider[key][0])
                        slider[key][0] = parseFloat(value[key]);
                    if(!slider[key][1] || parseFloat(value[key]) > slider[key][1])
                        slider[key][1] = parseFloat(value[key]);    
                })
            });
        })
        .catch(error => console.log(error));

    }, [])

    useEffect(() => {
        let newData = [...props.data];
        if(Object.keys(filter).length) {
            newData.map(function(val,index) {

                if(searchValue) {
                    if(JSON.stringify(val).indexOf(searchValue) == -1) {
                        delete newData[index];
                    }
                }
                
                Object.keys(filter).map(function(data, ind) {
                    let curfilter = false;
                    Object.keys(filter[data]).map(function(cur) {
                        if(JSON.stringify(val).indexOf(filter[data][cur]) > -1)
                            curfilter = true;
                    })
                    if(!curfilter)
                        delete newData[index];
                })
            })
        }

        if(Object.keys(slider).length) {
            newData.map(function(val,index) {
                Object.keys(slider).map(function(data, ind) {
                    if(val[data] < slider[data][0] || val[data] > slider[data][1])
                        delete newData[index];
                })
            })
        }

        setCurData(newData);
        setProductCount(newData.filter(Boolean).length);
    }, [filter, searchValue, slider])
    
    //onClick={() => props.addToOffer(val.id)}

    return (
        <>
        <div className="col-3">
            <div className="product_desc">
                <img src={process.env.REACT_APP_IMAGE_URL + props.variation.image} />
                <h4>Beschreibung</h4>
                    <RenderHTML HTML={props.variation.description} />
                    <br/>
             </div>
                <div className='category_data'>
                <div><strong>Spannung</strong>{props.variation.voltage_from} - {props.variation.voltage_to}</div>
                <div><strong>Schutzart [IP]</strong>{props.variation.protectiontype_ip}</div>
                <div><strong>Material</strong>{props.variation.material}</div>
                <div><strong>Betriebsgerät</strong> 
                    {props.variation.operatingdevice == 0 ? 'ohne Betriebsgerät' : 'mit Betriebsgerät'}</div>
                <div><strong>Lichtaustritt</strong>
                    {props.variation.lightoutput == 0 ? 'direkt' : 'indirekt'}
                </div>
                <h4>Filter</h4> 
                {renderedFilter && Object.keys(renderedFilter).length && 

                     Object.keys(renderedFilter).map(function(key,index){
                        return(
                        <div key={index} className="filter_buttons">
                         <h5>{global.config[key][language]}</h5>
                            <div>
                                <Filter data={renderedFilter[key]} products={props.data} filterKey={key} addFilter={addFilter}/>
                            </div>
                        </div>)
                    })               
                }
                {renderedSlider && renderedSlider['watt'].length == 2 &&

                    Object.keys(renderedSlider).map(function(key,index){
                        if(renderedSlider[key].length == 2) {
                            return(
                            <div key={index} className="filter_slider">
                                <h5>{global.config[key][language]}</h5>
                                <div>
                                    <MySlider data={renderedSlider[key]} slider={key} addSlider={addSliderFilter} />
                                </div>
                            </div>)
                        }
                    
                    })               
                }
                
            </div>
            
        </div>
        <div className="col-9">
            <div className="stickysection">
                <div className="productsearch">
                    <div>{productCount} Produkte</div>
                    <input type="text" placeholder="Suche" onChange={(e) => setSearchValue(e.target.value)}/>
                </div>
                <div id="products_table_container">
                    <table >
                        <thead>
                            <tr className="table_headline">
                                <th>Zum Angebot</th>
                                <th>{global.config.anr[language]}</th>
                                <th>{global.config.power[language]}</th>
                                <th>{global.config.lighttemperature[language]}</th>
                                <th>{global.config.luminous[language]}</th>
                                <th>{global.config.cri[language]}</th>
                                <th>{global.config.optic[language]}</th>
                                <th>{global.config.dimmable[language]}</th>
                                <th>L B H [mm]</th>
                                <th>{global.config.diameter_mm[language]} [mm]</th>
                                <th>{global.config.color[language]}</th>
                                <th>Zum Angebot</th>
                            </tr>
                        </thead>
                        <tbody>
                            {curData.length &&
                                curData.map(function(val,index) {
                                    return (
                                        <tr key={"product" + index} 
                                            className={(props.offerdata[(val.id - 1)] != null) ? 'active' : ''}>
                                            <td><i className="fas fa-heart" onClick={() => props.addToOffer(val.id - 1 )}></i></td>
                                            <td>{val.anr} </td>
                                            <td>{val.watt} W</td>
                                            <td>{val.lighttemperaturename} </td>
                                            <td>{val.lumen} lm</td>
                                            <td>{val.criname} </td>
                                            <td>{val.opticname}°</td>
                                            <td>{val.dimmablename} </td>
                                            <td>{val.lenght_mm} x {val.width_mm} x {val.height_mm} </td>
                                            <td>{val.diameter_mm} mm</td>
                                            <td>{val.colorname} </td>
                                            <td><i className="fas fa-heart" onClick={() => props.addToOffer(val.id - 1 )}></i></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>    
        </div>
    </>
    )      
}

export default EasyTable;

  