import { NavLink } from "react-router-dom";

export const columns = [
   
    {
        headerName: 'Email',
        field: 'email',
        width: 300,
    },
    {
        headerName: 'Datum',
        field: 'login_date',
        width: 300,
    },
]
export const columns_en = [
    {
        headerName: 'Email',
        field: 'email',
        width: 300,
    },
    {
        headerName: 'Date',
        field: 'login_date',
        width: 300,
    },
]
