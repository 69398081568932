import { useEffect, useState } from "react";

function MyInput(props) {
  const [val, setVal] = useState(props.data[props.config.field] ? props.data[props.config.field] : '');
  const [valTwo, setValTwo] = useState(props.data[props.config.field2] ? props.data[props.config.field2] : '');
  const [visible, setVisible] = useState(true);

  let user = JSON.parse(localStorage.getItem('kerion_user'));
  let language = localStorage.getItem('kerion_language');


  useEffect(() => { 
    //console.log(props.config.disabledforuser && user.roles.indexOf('ADMIN') == -1);
    if(props.config.disabledforuser && user.roles.indexOf('ADMIN') == -1)
        props.config.disabled = true;

    if((props.variationdata && props.config.field == 'lumen_' && props.variationdata['lightoutput'] == 1) ||
      (props.variationdata && props.config.field == 'lumen' && props.variationdata['lightoutput'] == 2) ||
      (props.variationdata && props.config.field == 'radiation_indirect' && props.variationdata['lightoutput'] == 1) ||
      (props.variationdata && props.config.field == 'radiation' && props.variationdata['lightoutput'] == 2) ) {
        setVisible(false);
    }else if(props.config.condition && !props.variationdata[props.config.condition[0]][props.config.condition[1]] == true){
        setVisible(false);
    }
    else {
        setVisible(true);
    }
  },[props.variationdata]);


  function changeVal(e = '', field = '', multiple = false) {
    if(field == 'files') {

      if(e == ''){
        setVal('');
        props.onChange(props.config.field, '');
      }      
      else if(e.target.files.length == 1 && !multiple) {
        setVal(e.target.files[0]);
        props.onChange(props.config.field, e.target.files[0], 'files');
      }else {
        
        //setVal(e.target.files[0]);
        let gallery = []

        for(var i = 0; i < e.target.files.length; i++) {
          gallery[i] = e.target.files[i];
        }
        props.onChange(props.config.field, gallery, 'files');
      }
      
    }
    else if(field){
      setValTwo(e.target.value);
      props.onChange(field, e.target.value);
    }else if(props.config.type == 'checkbox') {
      setVal(e.target.value);
      props.onChange(props.config.field, e.target.checked);
    }
    else {
      setVal(e.target.value);
      props.onChange(props.config.field, e.target.value);
    }
  }

  return (
    <>
    {visible && 
      <div>
      <label className={'labelcontainer type-' + props.config.type}>
        {(language == 'de' || !props.config.label_en) &&
          <span>{props.config.label} {props.config.required ? '*' : ''}</span>
        }
        {language == 'en' && props.config.label_en &&
          <span>{props.config.label_en} {props.config.required ? '*' : ''}</span>
        }
        <div className="inputcontainer">
        {props.config.type == 'file' && !props.config.multiple && 
          <>
            <div>
              {props.data[props.config.field] && props.config.field != 'ldt_name' &&
                <img width="200px" src={process.env.REACT_APP_IMAGE_URL  + props.data[props.config.field]} />}
              {props.data[props.config.field] && props.config.field == 'ldt_name' &&
                <span>{props.data[props.config.field]}</span>
              }
            </div>
            
            <input 
              id={props.config.field}
              type={props.config.type}
              placeholder={props.data[props.config.field]}
              readOnly={props.config.readonly}
              name={props.config.field}
              required={props.config.required}
              disabled={props.config.disabled ? 'disabled' : ''}
              onChange={(e) => changeVal(e, 'files')}
            /> 
            {props.data[props.config.field] &&
              <button onClick={(e) => { e.preventDefault(); changeVal('', 'files')}}>
                  <i className="fas fa-trash"></i>löschen
              </button>
            }
            
          </>
        }{props.config.type == 'file' && props.config.multiple && 
        <><div>
          {val && (JSON.parse(val)).map(function( key ,index) {
            return <img key={index} width="200px" src={process.env.REACT_APP_IMAGE_URL  + key} />
            })
          }</div>
          <input 
            type={props.config.type}
            placeholder={props.data[props.config.field]}
            readOnly={props.config.readonly}
            name={props.config.field}
            required={props.config.required}
            disabled={props.config.disabled ? 'disabled' : ''}
            multiple
            onChange={(e) => changeVal(e, 'files', 'multiple')}
          /> 
          </>
        }
        
        {(props.config.type == 'text' || props.config.type == 'number') && 
            
            <div>
              <input 
              type={props.config.type}
              readOnly={props.config.readonly}
              defaultValue={props.data[props.config.field]}
              name={props.config.field}
              required={props.config.required}
              step="0.01"
              disabled={props.config.disabled ? 'disabled' : ''}
              onChange={changeVal}
            /> 
          </div> 
        }{(props.config.type == 'text' || props.config.type == 'number') && props.config.field2 &&
          <div><input 
            type={props.config.type}
            placeholder={props.data[props.config.field2]}
            readOnly={props.config.readonly}
            defaultValue={props.data[props.config.field2]}
            name={props.config.field2}
            required={props.config.required}
            step="0.01"
            disabled={props.config.disabled ? 'disabled' : ''}
            onChange={(e) => changeVal(e, props.config.field2)}
          /> </div> 
        }
        {props.config.type == 'checkbox' &&
              <div className="checkboxcontainer">
                <input 
                  type={props.config.type}
                  placeholder={props.data[props.config.field]}
                  readOnly={props.config.readonly}
                  name={props.config.field}
                  required={props.config.required}
                  onChange={changeVal}
                  defaultChecked={val}
                  id={props.config.field }
                  disabled={(user.roles.indexOf('USER') > -1) ? 'disabled' : '' }
                /> <label htmlFor={props.config.field }>
                  
              </label>
            </div>
      }
        </div>
    </label></div>
  }
  </>
  );
}


export default MyInput;