import ConfirmDialog from './Dialog';

function Loading(props) {
    return (
        <>
        {props.loading.display && <div className='loading'>
        {props.loading.circles &&
        <div id="circles">
        <div className="circle0 circle"></div>
        <div className="circle1 circle"></div>
        <div className="circle2 circle"></div>
        <div className="circle3 circle"></div>
        <div className="circle4 circle"></div>
        <div className="circle5 circle"></div>
        <div className="circle6 circle"></div>
        <div className="circle7 circle"></div>
        <div className="circle8 circle"></div>
        <div className="circle9 circle"></div>
        </div>
        }
        {props.loading.dialog &&
            <ConfirmDialog 
            loading={props.loading}
            text={props.text}
            headline={props.headline}
            button={props.button}
            handleOk={props.handleOk} 
            handleClose={props.handleClose} 
            />
        }

    </div>}
    </>
    )
}

export default Loading;