import { useState, useEffect } from "react";
import axios from 'axios';


function PerformancePrice(props) {
  if(typeof props.data[props.config.field] == 'string')
    props.data[props.config.field] = JSON.parse(props.data[props.config.field])

  const [val, setVal] = useState(props.data[props.config.field] ? props.data[props.config.field] : []);
  const [priceVal, setPriceVal] = useState([]);

  

  function changeVal(e, index, name, id) {
    let value = [...val];
    value[index] = {'price': e.target.value, 'name' : name, 'dimmable_id' : id};
    setVal(value);
    props.onChange('performance_price', value);
  }

  useEffect(() => { 
    axios.get(process.env.REACT_APP_URL + 'getData.php?character=dimmable')
    .then(response =>  {
        setPriceVal(response.data);
    })
    .catch(error => console.log(error));
  }, []);

  return (
    <div className="performance_price">
    <h3>Preise</h3>
    {priceVal.length && priceVal.map((val, index) => {
        return (<label key={index} className={'labelcontainer type-' + props.config.type}>
        <span>{props.config.label} {val['name']}</span>
        <div className="inputcontainer">
            {(props.config.type == 'number') &&
            <div>
                <input 
                    type={props.config.type}
                    readOnly={props.config.readonly}
                    defaultValue={(props.data[props.config.field] && props.data[props.config.field][index]) ? props.data[props.config.field][index]['price'] : 0}
                    name={props.config.field}
                    required={props.config.required}
                    onChange={(e) => changeVal(e, index, val['name'], val['id'])}
                    />     
                </div> 
            }
            </div>
        </label>)
    })
        
    }
    </div>
  );
}


export default PerformancePrice;