import { useGridApiRef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import Sidemenu from '../Sidemenu';
import {Table} from '../Tables/Table';
import { columns as productColumns } from "../Tables/ProductConfig";
import axios from 'axios';
import Loading from '../Helper/Loading';
import { exportExcel } from '../Helper/excel';


function CombinationTable(props) {
  const [data, setData] = useState([]);
  const apiRefTable = useGridApiRef();
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  
  let language = localStorage.getItem("kerion_language");

  useEffect(() => {

      setLoading({'display': true, 'circles': true, 'dialog': false, 'error' :false});

      axios.get(process.env.REACT_APP_URL + 'getData.php?variationid='+ props.variationid)
        .then(response =>  {
            setData(response.data);
            setLoading({'display': false, 'circles': false, 'dialog': false, 'error' :false});
        })
        .catch(error => console.log(error));
      
  }, []);


  function handleOk() {
      window.location.reload();
  }

  async function downloadProducts() {
    const columns = productColumns.map(it => ({
      header: it.headerName, key: it.field
    }));
    //const ids = gridFilteredSortedRowIdsSelector(props.apiRefTable);
    //const filteredData = props.data.filter(it => ids.includes(it.id));
    await exportExcel(data, columns);
  }



  return (
    <div className="combinationstable">
       <Loading
            loading={loading}         
            headline={{'success' : global.config.successheadline[language], 'error' : global.config.errorheadline[language]}}
            text={{'success' :global.config.productsuccesstext[language], 'error' : global.config.producterrortext[language]}}
            button={{'ok' : true}}
            handleOk={() => setLoading({'display': false, 'circles': false, 'dialog': false})} 
        />
        
          <div className="table-buttons top">
              <button onClick={downloadProducts}>Als Excel downloaden</button>
              {/*<button onClick={regenerateProducts}>Produkte neu generieren</button>*/}
          </div>
          {data && 
            <Table data={data} mode="product" tablebuttons={false} apiRef={apiRefTable} />
          }
    </div>
  );
}

export default CombinationTable;