import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog(props) {

  const handleClose = (dialog) => {
    props.setOpen(false);
    props.deleteRows(dialog);
  };


  return (
    <div className={'dialog' + (props.loading.error ?? 'error') }>
      <Dialog
        open={props.loading.dialog}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {!props.loading.warning && !props.loading.error && props.headline.success}
          {props.loading.error && props.headline.error}
          {props.loading.warning && props.headline.warning}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!props.loading.error && props.text.success}
            {props.loading.error && props.text.error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.button.ok && !props.loading.warning &&
            <Button onClick={props.handleOk}>ok</Button>
          }

          {props.loading.warning && 
          <>
            <Button onClick={props.handleClose}>abbrechen</Button>
            <Button onClick={props.handleOk} autoFocus>
              löschen
            </Button></>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}