export const Elements = [
    /*{
        label: "Angebotsnummer",
        field: "offernumber",
        htmlfield: "input",
        type: 'number',
        readonly: true
    },*/
    {
        label: 'Kunde',
        label_en: 'Client',
        field: 'fullname',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required : true,
    },
    {
        label: 'Email',
        label_en: 'Email',
        field: 'email',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required : true,
    },
    {
        label: 'Straße',
        label_en : "Street",
        field: 'street',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required : true,
    },
    {
        label: 'Hausnummer',
        label_en : "Housenumber",
        field: 'housenumber',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required : true,
    },
    {
        label: 'Stiege',
        label_en : "Floor",
        field: 'floor',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Top',
        field: 'top',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Plz',
        label_en : "ZIP",
        field: 'zip',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required : true,
    },
    {
        label: 'Land',
        label_en : "Country",
        field: 'country',
        htmlfield: "select",
        type: 'countryselect',
        required : true,
        width: 200,
    },
    /*{
        label: 'Firmenbuchnummer',
        label_en : "Company Register Number",
        field: 'company_register_number',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },*/
    {
        label: 'UID',
        label_en : "UID",
        field: 'tax_number',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required : true,
    },
    {
        label: 'Rabatt',
        label_en : "Discount",
        field: 'discount',
        htmlfield: "input",
        type: 'text',
        width: 200,
        disabled : true
    },
    {
        label: 'Anmerkung',
        label_en : "Additional Info",
        field: 'zk',
        htmlfield: "wysiwig",
        type: 'wysiwig',
        width: 200,
    },
]
