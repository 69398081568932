import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Products from './Components/Products';
import Productvariations from './Components/Productvariations';
import Productvariationdetail from './Components/Details/ProductvariationDetail';
import ProductCharacterDetail from './Components/Details/ProductCharacterDetail';
import User from './Components/User';
import Userlogin from './Components/UserLogins';
import UserDetail from './Components/Details/UserDetail';
import Productcharacteristics from './Components/Productcharacteristics';
import Login from './Components/Login';
import UploadPrices from './Components/UploadPrices';
import Offer from './Components/Offer';
import OfferDetail from './Components/Details/OfferDetail';
import ForgotPwd from './Components/ForgotPwd';
import { useEffect, useState } from 'react';
import { getCookie, deleteCookie } from './Components/Helper/cookiefuntions';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(getCookie('kerion_login') ? true : false);

  useEffect(() => {
    if(!localStorage.getItem('kerion_user')) {
      localStorage.setItem('loggedIn', false);
      deleteCookie('kerion_login');
      setIsAuthenticated(false);
    }
    if(!isAuthenticated) {
      localStorage.setItem('loggedIn', false);
      localStorage.removeItem('kerion_user');
    }
  });

  return (
    <BrowserRouter>
      <Routes path="/" element={<Products />}>
        {!isAuthenticated && 
          <>
          <Route path="/*" element={<Login />} />
          <Route path="/resetpwd" element={<ForgotPwd/>} />
          </>
        }
        {isAuthenticated && 
          <>
            <Route index element={<Products />} />
            <Route path="/*" element={<Products />} />
            <Route path="products" element={<Products />} />
            <Route path="products/addprices" element={<UploadPrices />} />
            <Route path="productvariations" element={<Productvariations />} />
            <Route path="productvariations/:detail" element={<Productvariationdetail />} />

            <Route path="productcharacteristics/:character" element={<Productcharacteristics />} />
            <Route path="productcharacterdetail/:character" element={<ProductCharacterDetail />} />

            <Route path="users" element={<User />} />
            <Route path="userlogin" element={<Userlogin />} />
            <Route path="users/:user" element={<UserDetail />} />
            
            <Route path="offers" element={<Offer />} />
            <Route path="offers/offer" element={<OfferDetail />} />
            <Route path="offers/newoffer" element={<OfferDetail />} />

            <Route path="/login" element={<Login />} />
             
          </>
          
        }
          
      </Routes>   
    </BrowserRouter>
  );
}



export default App;
