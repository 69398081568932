import { useGridApiRef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import '../Css/main.css';
import Sidemenu from './Sidemenu';
import {Table} from './Tables/Table'
import axios from 'axios';
import Loading from './Helper/Loading';
import { deleteCookie } from './Helper/cookiefuntions';


function Productvariations() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  const apiRefTable = useGridApiRef();

  let language = localStorage.getItem("kerion_language");

  if(!localStorage.getItem('kerion_user')) {
    localStorage.setItem('loggedIn', false);
    deleteCookie('kerion_login');
    window.location.href = '/login';
  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_URL + 'getData.php?variations=true')
      .then(response =>  {
        console.log(response)
        setData(response.data);
      })
      .catch(error => console.log(error));
  }, []);

  function deleteVariation(ids) {
    setLoading({'display': true, 'circles': true, 'dialog': false, 'error' :false});

    axios.post(process.env.REACT_APP_URL + 'deleteData.php?variation=true', {'ids' : ids})
      .then(response =>  {
        console.log(response)
        if(response.data[0] == true) 
          setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : false});
        else
          setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : true});
      })
      .catch(error => console.log(error));
  }

  function handleOk() {
    window.location.reload();
  }

  return (
    <div className="main">
       <Loading
            loading={loading}         
            headline={{'success' : global.config.successheadline[language], 'error' : global.config.errorheadline[language]}}
            text={{'success' :global.config.productvdsuccesstext[language], 'error' : global.config.productvderrortext[language]}}
            button={{'ok' : true}}
            handleOk={handleOk} 
        />
        
        <Sidemenu/>
        <section>
          <h1>Produktvariationen</h1>
          {data.length > 0 && 
            <Table data={data} mode="productvariations"
              link={"/productvariations/detail?new=true"} 
              linktext="Neue Variation hinzufügen"
              tablebuttons={true} onDelete={deleteVariation} 
              apiRef={apiRefTable} />
          }
        </section>

    </div>
  );
}

export default Productvariations;