import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../Css/main.css';
import Loading from './Helper/Loading';
import axios from 'axios';


function ForgotPwd(props) {
  const [pwd2, setPwd2] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});

  const [searchParams, setSearchParams] = useSearchParams(props.searchParams);

  let user = searchParams.get("user");
  let language = searchParams.get("lang");

  const [dialogText, setDialogText] = useState({text: global.config.changepwdsuccesstext[language]});
  

  const handleSubmit = (event) => {
    event.preventDefault();
    
    
    if(!password) {
      setError({'error' : true, 'password' : true});
      
    }else {
      setLoading({'display': true, 'circles': true, 'dialog': false, 'error' :false});
      console.log(user);

      axios.post(process.env.REACT_APP_URL + 'changepwd.php', {newpwd : password, url : user})
        .then(response =>  {
          console.log(response);

          if(response.data[0] == true){
            setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : false});
              
          }else {
              setDialogText({text: global.config.changepwderrortext[language]});
              setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : true});
          }
              
        })
        .catch(error => console.log(error));
      }
  };
  
  function checkPwd(pwd) {
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    var format2 = /[0-9]/;
    let curerror = {error : false, 'errorChar' : false, 'pwd' : false};

    if(pwd.length < 8 || !format.test(pwd) || !format2.test(pwd) ){
      curerror = {error : true, 'errorChar' : true};
    }if(password != pwd2) {
      curerror = {...curerror, error : true, 'pwd' : true};
    }
    
    setError(curerror);
    
  }

  function handleOk() {
    //window.location.href= '/login';
    //window.location.reload();
    props.onSuccess();
  }

  return (
    <section className='loginsection'>
      <Loading
            loading={loading}         
            headline={{'success' : global.config.successheadline[language], 'error' : global.config.errorheadline[language]}}
            text={{'success' : dialogText.text, 'error' : dialogText.text}}
            button={{'ok' : true}}
            handleOk={handleOk} 
        />
        
        <div id="login" className={`popup`}>
          <a href="/"><img src="https://www.kerionlighting.com/wp-content/uploads/2024/01/kerion-logo-dark.png"  /></a>    
            
              <form onSubmit={handleSubmit}>
                <h1>{global.config.resetpwd[language]}</h1>
                
                
                <label>
                    <input placeholder={global.config.password[language] + "*"} type="password" name="pwd" value={password} 
                      onKeyUp={(e) => checkPwd(e.target.value)} 
                      onChange={(e) => setPassword( e.target.value)} />
                    {error.error && error.errorChar && <span className="errortext">{global.config.passwordcheck[language]}</span>}
                </label>
                <label>
                    <input placeholder={global.config.passwordrepeat[language] + "*"}  type="password" name="pwd2" 
                    onKeyUp={(e) => checkPwd(e.target.value)} 
                    value={pwd2} onChange={(e) => setPwd2(e.target.value)} />
                    {error.error && error.pwd && <span className="errortext">{global.config.unequal[language]}</span>}
                </label>

                <button type="submit" disabled={error.error ?? 'disabled'}>{global.config.resetpwd[language]}</button>

              
            </form>
            
        </div>
    </section>
  );
}

export default ForgotPwd;