export const Elements = [
    {
        label: "ID",
        field: "id",
        htmlfield: "input",
        type: 'number',
        readonly: true,
        hidenew : true
    },
    {
        label: 'Variation Name',
        field: 'product_variation_name',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true
    },
    {
        label: 'Kategorie ID',
        field: 'product_category_id',
        databasetable: 'product_category',
        htmlfield: "select",
        type: 'select',
        width: 100,
        required: true,
    },
    {
        label: 'Variation Prefix',
        field: 'prefix',
        htmlfield: "input",
        type: 'number',
        width: 100,
        required: true
    },
    {
        label: 'Beschreibung',
        field: 'description',
        htmlfield: "wysiwig",
        type: 'wysiwig',
        width: 100,
    },
    {
        label: 'Beschreibung En',
        field: 'description_en',
        htmlfield: "wysiwig",
        type: 'wysiwig',
        width: 100,
    },
    {
        label: 'Lieferumfang',
        field: 'delivery_scope_de',
        htmlfield: "wysiwig",
        type: 'wysiwig',
        width: 100,
    },
    {
        label: 'Lieferumfang En',
        field: 'delivery_scope_en',
        htmlfield: "wysiwig",
        type: 'wysiwig',
        width: 100,
    },
]

export const Images = [
    {
        label: 'Bild*',
        field: 'image',
        htmlfield: "input",
        type: 'file',
        width: 100,
    },
    {
        label: "Projekt Bild",
        field: "project_image",
        htmlfield: "input",
        type: 'file',
    },
    {
        label: "Technisches Bild",
        field: "technical_image",
        htmlfield: "input",
        type: 'file',
    },
    {
        label: "Bilder Galerie",
        label_en: "Image Gallery",
        field: "product_gallery",
        htmlfield: "input",
        multiple: true,
        type: 'file',
    },
    {
        label: "LDT Datei",
        field: "ldt_name",
        htmlfield: "input",
        type: 'file',
    },
]

export const VariationCharacters = [
    {
        label: "Farben",
        field: "color",
        htmlfield: "checkbox",
        type: 'checkbox',
        crosstable: 'cross',
        databasetable: 'product_character_color',
        databaseVariationTable: 'product_variation_color',
        default: {2:true,3:true}
    },
    {
        label: "Lichttemperatur",
        field: "lighttemperature",
        htmlfield: "checkbox",
        type: 'checkbox',
        crosstable: 'cross',
        databasetable: 'product_character_lighttemperature',
        databaseVariationTable: 'product_variation_lighttemperature',
        default: {1:true,2:true}
    },
    {
        label: "Abstahlwinkel",
        field: "optic",
        htmlfield: "checkbox",
        type: 'checkbox',
        crosstable: 'cross',
        databasetable: 'product_character_optic',
        databaseVariationTable: 'product_variation_optic',
    },
    {
        label: "CRI",
        field: "cri",
        htmlfield: "checkbox",
        type: 'checkbox',
        crosstable: 'cross',
        databasetable: 'product_character_cri',
        databaseVariationTable: 'product_variation_cri',
        default: {1:true,2:true}
    },
    {
        label: "Dimmbar",
        field: "dimmable",
        htmlfield: "checkbox",
        type: 'checkbox',
        crosstable: 'cross',
        databasetable: 'product_character_dimmable',
        databaseVariationTable: 'product_variation_dimmable',
        default: {1:true,2:true}
    },
    {
        label: "Holzoptionen",
        field: "woodoption",
        htmlfield: "checkbox",
        type: 'checkbox',
        crosstable: 'cross',
        databasetable: 'product_character_woodoption',
        databaseVariationTable: 'product_variation_woodoption',
    },
    {
        label: "Holzoberflächen",
        field: "woodsurface",
        htmlfield: "checkbox",
        type: 'checkbox',
        databasetable: 'product_character_woodsurface',
        databaseVariationTable: 'product_variation_woodsurface',
    },
    {
        label: "Spannung von / bis",
        field: "voltage_from",
        htmlfield: "input",
        type: 'number',
        default : 220,
        
        label2: "Spannung bis",
        field2: "voltage_to",
        htmlfield2: "input",
        type2: 'number',
        default2 : 240
    },
    {
        label: "Hertz von / bis",
        field: "hz_from",
        htmlfield: "input",
        type: 'number',
        default : 50,
        label2: "Spannung bis",
        field2: "hz_to",
        htmlfield2: "input",
        type2: 'number',
        default2 : 60
    },
    {
        label: 'Material',
        field: 'material',
        htmlfield: "input",
        type: 'text',
        default : 'Aluminium'
    },
    {
        label: 'Schutzart IP',
        field: 'protectiontype_ip',
        htmlfield: "input",
        type: 'number',
    },
    {
        label: 'Schutzklasse',
        field: 'protectionclass',
        htmlfield: "input",
        type: 'number',
    },
    {
        label: 'Lebensdauer',
        field: 'lifespan',
        htmlfield: "input",
        type: 'text',
    },

    {
        label: "Umgebungstemperatur",
        field: "ambient_temperature",
        htmlfield: "input",
        type: 'text',
        default: '-20 bis +45 °C'
    },
    {
        label: 'Anschluss',
        field: 'power_supply',
        htmlfield: "input",
        type: 'text',
        default : ''
    },
]

export const additionalVariationCharacters = [
    {
        label: "Direkt/Indirekt",
        field: "lightoutput",
        htmlfield: "select",
        type: 'select',
        databasetable: 'product_character_lightoutput',
        default: [3]
    },
    {
        label: "Abdeckung",
        field: "ugr",
        htmlfield: "radio",
        type: 'radio',
        crosstable: 'cross',
        databasetable: 'product_character_ugr',
        databaseVariationTable: 'product_variation_ugr',
    },
    {
        label: "UGR",
        field: "ugr_indirect",
        htmlfield: "input",
        type: 'text',
    },
    {
        label: 'Abstrahlung',
        field: 'radiation',
        htmlfield: "input",
        type: 'text',
    },
    /*{
        label: 'Abstrahlung (indirekt)',
        field: 'radiation_indirect',
        htmlfield: "input",
        type: 'text',
    },*/
    {
        label: "Betriebsgerät",
        field: "operatingdevice",
        htmlfield: "input",
        type: 'checkbox',
        default: true
    },
    {
        label: "Aktiv",
        field: "visible",
        htmlfield: "input",
        type: 'checkbox',
        default: true
    }
]

export const Performance = [
    {
        label: "Prefix*",
        field: "prefix",
        htmlfield: "input",
        type: 'number',
    },
    {
        label: "Watt*",
        field: "watt",
        htmlfield: "input",
        type: 'number',
    },
    {
        label: "Höhe",
        field: "height_mm",
        htmlfield: "input",
        type: 'number',
    },
    {
        label: "Breite",
        field: "width_mm",
        htmlfield: "input",
        type: 'number',
    },
    {
        label: "Länge",
        field: "length_mm",
        htmlfield: "input",
        type: 'number',
    },
    {
        label: "Durchmesser",
        field: "diameter_mm",
        htmlfield: "input",
        type: 'text',
    },
    {
        label: "Deckenausschnitt",
        field: "outline",
        htmlfield: "input",
        type: 'text',
    },
    {
        label: "Netto Gewicht",
        field: "weight",
        htmlfield: "input",
        type: 'number',
    },
    {
        label: "Lumen (direkt)",
        field: "lumen",
        htmlfield: "input",
        type: 'number',
    },
    {
        label: "Lumen (indirekt)",
        field: "lumen_indirect",
        htmlfield: "input",
        type: 'number',
    },
    {
        label: "Preis (on/off)",
        field: "performance_price",
        htmlfield: "input",
        type: 'number',
    },
    {
        label: "Preis (dali)",
        field: "performance_price_dali",
        htmlfield: "input",
        condition: ['cross_product_variation_dimmable', 2],
        type: 'number',
    },
    {
        label: "Preis (1-10V)",
        field: "performance_price_110V",
        htmlfield: "input",
        condition: ['cross_product_variation_dimmable', 3],
        type: 'number',
    }
   
]