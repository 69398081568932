import { MenuItem, Menu, MenuList } from '@mui/material';
import '../Css/navigation.css';
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from '../Images/kerion-logo-dark.png';

import { deleteCookie } from './Helper/cookiefuntions';



function Sidemenu() {
  const [userData, getUserData] = useState(localStorage.getItem("kerion_user") ? JSON.parse(localStorage.getItem("kerion_user")) : null);
  const [sidemenuopen, setSidemenuopen] = useState(true);

  let role = userData ? userData.roles : null;
  let language = localStorage.getItem("kerion_language") ?? 'de';

  function changeLanguage() {
    if(language == 'de')
      localStorage.setItem("kerion_language","en");
    else
      localStorage.setItem("kerion_language","de");

      window.location.reload();
  }

  useEffect(() => {
    if(!localStorage.getItem('kerion_user')) {
      localStorage.setItem('loggedIn', false);

      deleteCookie('kerion_login');
    }
  });

  return ( 
    <>
    {userData && 
       <>
      <div id="sidemenu" className={sidemenuopen ? 'open' : ''}>
        <div>
          <div className="logocontainer">
            <div id="sidemenuclose" >
              <i className='fas fa-chevron-left' onClick={() => setSidemenuopen(false)}></i>
              <i className='fas fa-chevron-right' onClick={() => setSidemenuopen(true)}></i>
            </div>
            
            <img id="logo" src={logo} />
            <h2>Portal</h2>
          </div>
        
          <nav className='menu'>
            
              
              <h3>{global.config.products[language]}</h3>
              <NavLink  to="/products"><i className="fa-regular fa-lightbulb"></i>{global.config.products[language]}</NavLink > 
              
             {role && role.includes('ROLE_ADMIN') && 
              <>
                <ul className='submenu'>
                  <NavLink  to="/products/addprices"><i className="fa-solid fa-upload"></i>{global.config.addprices[language]}</NavLink > 
                </ul>
                <NavLink  to="/productvariations"><i className="fa-solid fa-lightbulb"></i>{global.config.productvariation[language]}</NavLink >
                <ul className='submenu'>
                  <NavLink  to="/productvariations/variation?new=true"><i className="fa-solid fa-plus">
                    </i>{global.config.addvariation[language]}</NavLink > 
                </ul>

                <h3>{global.config.productcharacteristic[language]}</h3>
                {/*<NavLink  to="/productcharacteristics/performancedata">Leistungswerte</NavLink >*/}
                <NavLink  to="/productcharacteristics/category">{global.config.productcategories[language]}</NavLink >
                <NavLink  to="/productcharacteristics/color">{global.config.colors[language]}</NavLink >
                <NavLink  to="/productcharacteristics/cri">Cri</NavLink >
                <NavLink  to="/productcharacteristics/optic">{global.config.optic[language]}</NavLink >
                <NavLink  to="/productcharacteristics/lighttemperature">{global.config.lighttemperature[language]}</NavLink >
                <NavLink  to="/productcharacteristics/dimmable">{global.config.dimmable[language]}</NavLink >
                <NavLink  to="/productcharacteristics/ugr">{global.config.cover[language]}</NavLink >
                <NavLink  to="/productcharacteristics/woodoption">{global.config.woodoption[language]}</NavLink >
                <NavLink  to="/productcharacteristics/woodsurface">{global.config.woodsurface[language]}</NavLink >

                <h3>User</h3>
                  <NavLink  to="/users">User</NavLink >
                  <NavLink  to="/userlogin">Userlogin</NavLink >
              </>
            }
             
            

              <h3>{global.config.offer[language]}</h3>
              <NavLink  to="/offers">{global.config.offer[language]}</NavLink >
              <ul className='submenu'>
                <NavLink  to="/offers/newoffer?new=true"><i className="fa-solid fa-plus"></i>{global.config.newoffer[language]}</NavLink >
              </ul>

              <>
                <h3>{global.config.discover[language]}</h3>
                <a href="https://b2b.kerionlighting.com/images/Kerion-catalogue.pdf" target="_blank"><i className='fas fa-book'></i>{global.config.catalogue[language]}</a>
                <a href="https://b2b.kerionlighting.com/images/Kerion-folder.pdf"  target="_blank"><i className='fas fa-sheet-plastic'></i>{global.config.productfolder[language]}</a>
                <a href="mailto:taner.alkan@kerionlighting.com"><i className='fas fa-envelope'></i>{global.config.makecontact[language]}</a>
              </>

          </nav>
        </div>
          
      </div>
      <div className='loggedin'>
        <a href={"/users/user?user=" + userData.id} ><i className='fas fa-user'></i>{userData.fullname}</a>

        <a href="#" onClick={changeLanguage} ><i className='fas fa-globe'></i>
          {language == 'de' ? 'EN' : 'DE'}
        </a>
          <div className="" onClick={() => {
            localStorage.setItem('loggedIn', false);
            localStorage.removeItem('kerion_user');
            deleteCookie('kerion_login');

            window.location.href="/login"} }>Logout</div>
      </div>
      </>
      }{!userData &&
        <div className='loggedin'>
          <a href="#" onClick={changeLanguage} ><i className='fas fa-globe'></i>
            {language == 'de' ? 'EN' : 'DE'}
          </a>
        </div>
      }
    </>
    
  );
}

export default Sidemenu;