export const Cri = [
    {
        label: "ID",
        field: "id",
        htmlfield: "input",
        type: 'number',
        readonly: true
    },
    {
        label: 'Name',
        field: 'name',
        htmlfield: "input",
        type: 'text',
        width: 200,
        readonly: false,
        required: true
    },
    {
        label: 'Prefix',
        field: 'prefix',
        htmlfield: "input",
        type: 'number',
        width: 100,
        readonly: false,
        required: true
    },
]

export const Color = [
    {
        label: "ID",
        field: "id",
        htmlfield: "input",
        type: 'number',
        readonly: true
    },
    {
        label: 'Name',
        field: 'name',
        htmlfield: "input",
        type: 'text',
        width: 200,
        readonly: false,
        required: true
    },
    {
        label: 'Prefix',
        field: 'prefix',
        htmlfield: "input",
        type: 'number',
        width: 100,
        readonly: false,
        required: true
    },
]
export const Optic = [
    {
        label: "ID",
        field: "id",
        htmlfield: "input",
        type: 'number',
        readonly: true
    },
    {
        label: 'Name',
        field: 'name',
        htmlfield: "input",
        type: 'text',
        width: 200,
        readonly: false,
        required: true
    },
    {
        label: 'Prefix',
        field: 'prefix',
        htmlfield: "input",
        type: 'number',
        width: 100,
        readonly: false,
        required: true
    },
]
export const Lighttemperature = [
    {
        label: "ID",
        field: "id",
        htmlfield: "input",
        type: 'number',
        readonly: true
    },
    {
        label: 'Name',
        field: 'name',
        htmlfield: "input",
        type: 'text',
        width: 200,
        readonly: false,
        required: true
    },
    {
        label: 'Prefix',
        field: 'prefix',
        htmlfield: "input",
        type: 'number',
        width: 100,
        readonly: false,
        required: true
    },
]
export const Dimmable = [
    {
        label: "ID",
        field: "id",
        htmlfield: "input",
        type: 'number',
        readonly: true
    },
    {
        label: 'Name',
        field: 'name',
        htmlfield: "input",
        type: 'text',
        width: 200,
        readonly: false,
        required: true
    },
    {
        label: 'Prefix',
        field: 'prefix',
        htmlfield: "input",
        type: 'number',
        width: 100,
        readonly: false,
        required: true
    },
]

export const Ugr = [
    {
        label: "ID",
        field: "id",
        htmlfield: "input",
        type: 'number',
        readonly: true
    },
    {
        label: 'Name',
        field: 'name',
        htmlfield: "input",
        type: 'text',
        width: 200,
        readonly: false,
        required: true
    },
    {
        label: 'Name En',
        field: 'name_en',
        htmlfield: "input",
        type: 'text',
        width: 200,
        readonly: false,
        required: true
    },
    {
        label: 'Prefix',
        field: 'prefix',
        htmlfield: "input",
        type: 'number',
        width: 100,
        readonly: false,
        required: true
    },
]

export const Woodoption = [
    {
        label: "ID",
        field: "id",
        htmlfield: "input",
        type: 'number',
        readonly: true
    },
    {
        label: 'Name',
        field: 'name',
        htmlfield: "input",
        type: 'text',
        width: 200,
        readonly: false,
        required: true
    },
    {
        label: 'Prefix',
        field: 'prefix',
        htmlfield: "input",
        type: 'number',
        width: 100,
        readonly: false,
        required: true
    },
]

export const Woodsurface = [
    {
        label: "ID",
        field: "id",
        htmlfield: "input",
        type: 'number',
        readonly: true
    },
    {
        label: 'Name',
        field: 'name',
        htmlfield: "input",
        type: 'text',
        width: 200,
        readonly: false,
        required: true
    },
    {
        label: 'Name En',
        field: 'name_en',
        htmlfield: "input",
        type: 'text',
        width: 200,
        readonly: false,
        required: true
    },
    {
        label: 'Prefix',
        field: 'prefix',
        htmlfield: "input",
        type: 'number',
        width: 100,
        readonly: false,
        required: true
    },
]

export const Category = [
    {
        label: "ID",
        field: "id",
        htmlfield: "input",
        type: 'number',
        readonly: true
    },
    {
        label: 'Name*',
        field: 'name',
        htmlfield: "input",
        type: 'text',
        width: 200,
        readonly: false,
        required: true
    },
    {
        label: 'Name En*',
        field: 'name_en',
        htmlfield: "input",
        type: 'text',
        width: 200,
        readonly: false,
        required: true
    },
    {
        label: 'Prefix*',
        field: 'prefix',
        htmlfield: "input",
        type: 'text',
        width: 100,
        readonly: false,
        required: true
    },
    {
        label: "Bild",
        field: "image",
        htmlfield: "input",
        type: 'file',
    },
]