import { useEffect, useState } from "react";
import logo from '../../Images/kerion-logo-dark.png';
import {countryOptions} from '../Helper/countrySelectOptions';

function OfferPreview(props) {
  const [sum, setSum] = useState(0);
  let user = JSON.parse(localStorage.getItem('kerion_user')); 
  let language = localStorage.getItem("kerion_language");

  console.log(props);

  useEffect(function() {
    let summe = 0;

    Object.keys(props.offerData).map((value, index) => {
      let val = props.offerData[value];

      if(val.price != null && val.price != 0 ) {
        summe +=val.price * val.amount;
      }else if(!val.price && val.oldprice != null) {
        summe += val.oldprice * val.amount;
      }
    });

    setSum(summe);
  });

  return (
    <>
       <div className="offerdata preview">
            <div><h3>{/*Vorschau*/}</h3> </div>
            <div id="preview">

              <div>
                <div className="clientdata preview kerion">
                <div className="">
                  <img src={logo} />
                </div>
                <div>
                  <div><b>Kerion Lighting</b></div>
                  <div>Taner Alkan</div>
                  <div>Käthe Dassler Strasse 4</div>
                  <div>A-4770 Andorf</div>
                  <div>Österreich</div>
                </div>
              </div>
              
              <div className="clientdata preview">
              <div>{props.clientData.fullname}</div>
              <div>{props.clientData.street} {props.clientData.housenumber} {props.clientData.floor} {props.clientData.door}</div>
              <div>{props.clientData.zip} {props.clientData.city}</div>
              <div>{countryOptions.map((val, key) => {
                    if(val.id == props.clientData.country)
                    return val.name;
                })
              }
              </div>
              </div>
              <div className="offerpositions preview">
              <h3>{global.config.offer[language]}</h3>
              <table className='offertable'>
                    <thead>
                      <tr className='offerlist'>
                          <th>{global.config.anr[language]}</th>
                          <th>{global.config.variationimage[language]}</th>
                          <th>{global.config.variationperformance[language]}</th>
                          <th>{global.config.productperformance[language]}</th>
                          <th>{global.config.amount[language]}</th>
                          <th>{global.config.performance_price[language]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                  {
                      Object.keys(props.offerData).map((value, index) => {
                          let val = props.offerData[value];

                          return (
                              <tr className='offerlist' key={index}>
                                  <td><b>{val.product_variation_name}</b><p>{val.anr}</p></td>
                                  <td><img width="100px" src={process.env.REACT_APP_IMAGE_URL + val.image} /></td>
                                  <td>
                                    <div><span>{global.config.color[language]} :</span> <span>{val.colorname}</span></div>
                                    <div><span>{global.config.optic[language]} :</span> <span>{val.opticname}</span></div>
                                    <div><span>{global.config.dimmable[language]} :</span> <span>{val.dimmablename}</span></div>
                                  </td>
                                  <td>
                                    <div><span>{global.config.lighttemperature[language]} :</span> <span>{val.lighttemperaturename}</span></div>
                                    <div><span>{global.config.cri[language]} :</span> <span>{val.criname}</span></div>
                                    <div><span>{global.config.watt[language]} :</span> <span>{val.watt}</span></div>
                                    <div><span>{global.config.lumen[language]} :</span> <span>{val.lumen}</span></div>
                                    <div><span>{global.config.masse[language]} :</span> <span>{val.length_mm} x {val.height_mm} x {val.width_mm}</span></div>
                                    <div><span>{global.config.diameter_mm[language]} :</span> <span>{val.diameter_mm}</span></div>
                                    <div><span>{global.config.weight[language]} :</span> <span>{val.weight}</span></div>
                                    <div><span>{global.config.outline[language]} :</span> <span>{val.outline}</span></div>
                                    {val.woodoptionname && <div><span>{global.config.woodoption[language]} :</span> <span>{val.woodoptionname}</span></div>}
                                    {val.woodsurfacename && <div><span>{global.config.woodsurface[language]} :</span> <span>{val.woodsurfacename}</span></div>}
                                  </td>
                                  <td>{val.amount}</td>
                                  <td>
                                    {user.is_verified == 1 && ((val.price != null && val.price != 0 ) && (val.price * val.amount).toFixed(2))}
                                    {user.is_verified == 1 && ((!val.price && val.oldprice != null) ?? (val.oldprice * val.amount).toFixed(2))}

                                    {user.is_verified == 0 && (val.price != null && val.price != 0 ) && (val.price * val.amount * 1.2 ).toFixed(2)}
                                    {user.is_verified == 0 && ((!val.price && val.oldprice != null) ?? (val.oldprice * val.amount * 1.2).toFixed(2))}
                                    €
                                    
                                  </td>
                              </tr>
                          )
                      })
                  }
                  {user.country != 'aut' && 
                    <tr className="sum net">
                      <td style={{textAlign : 'right'}} colSpan={4}><b>{global.config.totalprice[language]}</b></td>
                      <td style={{textAlign : 'right'}} colSpan={2}><b>{(sum).toFixed(2)}€</b></td>       
                    </tr>
                  }
                  {user.country == 'aut' && 
                    <>
                      <tr className="sum net">
                        <td style={{textAlign : 'right'}} colSpan={4}>{global.config.totalprice[language]}</td>
                        <td style={{textAlign : 'right' }} colSpan={2}>{(sum).toFixed(2)}€</td>       
                      </tr>
                      <tr className="sum vat">
                        <td style={{textAlign : 'right'}} colSpan={4}>{global.config.vat[language]}</td>
                        <td style={{textAlign : 'right'}} colSpan={2}>{(sum / 5).toFixed(2)}€</td>
                      </tr>
                        <tr className="sum gross">
                          <td style={{textAlign : 'right'}} colSpan={4}><b>{global.config.grossprice[language]}</b></td>
                          <td style={{textAlign : 'right'}} colSpan={2}>{(sum * 1.2).toFixed(2)}€</td>
                      </tr>
                    </>
                  }
                  
                  </tbody>
              </table>
              {props.clientData.discount &&
                <div className="discountExplanation">{global.config.discountacception[language]}</div>
               }
              </div>
            </div>
            <div className="offerpositionfooter preview">
                <div>
                <p>Kerion Lighting Agency</p>
                <p>K.-Dassler-Straße 4</p>
                <p>4770 Andorf</p>
                <p>taner.alkan@kerionlighting.com</p>
                <p>www.kerionlighting.com</p>
                </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default OfferPreview;