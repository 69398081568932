import {Elements} from './ProductvariationDetailConfig';
import MyInput from '../Elements/Input';
import Select from '../Elements/Select';
import Wysiwig from '../Elements/RichEditor';
import Checkbox from '../Elements/Checkbox';
import Headline from '../Elements/Headline';
import Radiobutton from '../Elements/Radiobutton';
import PerformancePrice from '../Elements/PerformancePrice';


function FormItems(props) {

  return (
    <>
        {Object.keys(props.elements).map(function(val,index) { 
            if(props.type != 'new' || !props.elements[val].hidenew) {
                switch(props.elements[val].htmlfield) {
                    case 'input':
                    return <MyInput key={index}
                        onChange={function(key, val, array){props.handleChange(key, val, array)}} 
                        data={props.data ? props.data : ''} 
                        variationdata={props.variationdata ? props.variationdata : props.data} 
                        config={props.elements[val]}
                        type={props.type} />
                    case 'checkbox':
                        return <Checkbox key={index}
                            onChange={function(key, val){props.handleChange(key, val)}} 
                            data={props.data ? props.data : ''} 
                            variationdata={props.variationdata ? props.variationdata : props.data} 
                            config={props.elements[val]}
                            type={props.type} />
                    case 'radio':
                        return <Radiobutton key={index}
                            onChange={function(key, val){props.handleChange(key, val)}} 
                            data={props.data ? props.data : ''} 
                            variationdata={props.variationdata ? props.variationdata : props.data} 
                            config={props.elements[val]}
                            type={props.type} />
                    case 'select':
                        return <div key={index} ><Select 
                        onChange={function(key, val){props.handleChange(key, val)}} 
                        data={props.data ? props.data : ''} 
                        config={props.elements[val]}
                        type={props.type} />
                        </div>;
                    case 'wysiwig':
                        return <div className='richeditor' key={index} ><Wysiwig 
                        onChange={function(key, val){props.handleChange(key, val)}} 
                        data={props.data ? props.data : ''} 
                        config={props.elements[val]} 
                        type={props.type}
                        />
                        </div>;
                    case 'performance_price':
                        return <div className={'performance ' + props.type} key={index} >
                        <PerformancePrice 
                        onChange={function(key, val){props.handleChange(key, val)}} 
                        data={props.data ? props.data : ''} 
                        config={props.elements[val]} 
                        type={props.type}
                        />
                        </div>;
                    case 'headline':
                        return <div className='headline' key={index} ><Headline 
                        data={props.data ? props.data : ''} 
                        config={props.elements[val]} 
                        type={props.type}
                        />
                        </div>;
                }
            }
            })
        }
    </>
  );
}

export default FormItems;