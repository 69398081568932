import { NavLink } from "react-router-dom";

export const criColumns = [

    {
        headerName: "ID",
        field: "id",
        flex: 2,
        width: 100,
    },
    {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Prefix',
        field: 'prefix',
        flex: 2,
        width: 100
    },
    {
        headerName: 'Actions',
        flex: 2,
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NavLink className={"table-link"} to={`/productcharacterdetail/cri?detail=${params.row['id']}`} title={params.value}><i className="fa-solid fa-pencil"></i> Bearbeiten </NavLink>
                </div>
            )
        }
    },
]


export const colorColumns = [

    {
        headerName: "ID",
        field: "id",
        flex: 2,
        width: 100,
    },
    {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Prefix',
        field: 'prefix',
        flex: 2,
        width: 100
    },
    {
        headerName: 'Actions',
        flex: 2,
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NavLink className={"table-link"} to={`/productcharacterdetail/color?detail=${params.row['id']}`} title={params.value}><i className="fa-solid fa-pencil"></i> Bearbeiten </NavLink>
                </div>
            )
        }
    },
]


export const opticColumns = [

    {
        headerName: "ID",
        field: "id",
        flex: 2,
        width: 100,
    },
    {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Prefix',
        field: 'prefix',
        flex: 2,
        width: 100
    },
    {
        headerName: 'Actions',
        flex: 2,
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NavLink className={"table-link"} to={`/productcharacterdetail/optic?detail=${params.row['id']}`} title={params.value}><i className="fa-solid fa-pencil"></i> Bearbeiten </NavLink>
                </div>
            )
        }
    },
]

export const lighttemperatureColumns = [

    {
        headerName: "ID",
        field: "id",
        flex: 2,
        width: 100,
    },
    {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Prefix',
        field: 'prefix',
        flex: 2,
        width: 100
    },
    {
        headerName: 'Actions',
        flex: 2,
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NavLink className={"table-link"} to={`/productcharacterdetail/lighttemperature?detail=${params.row['id']}`} title={params.value}><i className="fa-solid fa-pencil"></i> Bearbeiten </NavLink>
                </div>
            )
        }
    },
]

export const dimmableColumns = [

    {
        headerName: "ID",
        field: "id",
        flex: 2,
        width: 100,
    },
    {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Prefix',
        field: 'prefix',
        flex: 2,
        width: 100
    },
    {
        headerName: 'Actions',
        flex: 2,
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NavLink className={"table-link"} to={`/productcharacterdetail/dimmable?detail=${params.row['id']}`} title={params.value}><i className="fa-solid fa-pencil"></i> Bearbeiten </NavLink>
                </div>
            )
        }
    },
]

export const ugrColumns = [

    {
        headerName: "ID",
        field: "id",
        flex: 2,
        width: 100,
    },
    {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Name En',
        field: 'name_en',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Prefix',
        field: 'prefix',
        flex: 2,
        width: 100
    },
    {
        headerName: 'Actions',
        flex: 2,
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NavLink className={"table-link"} to={`/productcharacterdetail/ugr?detail=${params.row['id']}`} title={params.value}><i className="fa-solid fa-pencil"></i> Bearbeiten </NavLink>
                </div>
            )
        }
    },
]

export const woodoptionColumns = [

    {
        headerName: "ID",
        field: "id",
        flex: 2,
        width: 100,
    },
    {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Prefix',
        field: 'prefix',
        flex: 2,
        width: 100
    },
    {
        headerName: 'Actions',
        flex: 2,
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NavLink className={"table-link"} to={`/productcharacterdetail/woodoption?detail=${params.row['id']}`} title={params.value}><i className="fa-solid fa-pencil"></i> Bearbeiten </NavLink>
                </div>
            )
        }
    },
]

export const woodsurfaceColumns = [

    {
        headerName: "ID",
        field: "id",
        flex: 2,
        width: 100,
    },
    {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Name En',
        field: 'name_en',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Prefix',
        field: 'prefix',
        flex: 2,
        width: 100
    },
    {
        headerName: 'Actions',
        flex: 2,
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NavLink className={"table-link"} to={`/productcharacterdetail/woodsurface?detail=${params.row['id']}`} title={params.value}><i className="fa-solid fa-pencil"></i> Bearbeiten </NavLink>
                </div>
            )
        }
    },
]

export const categoryColumns = [

    {
        headerName: "ID",
        field: "id",
        flex: 2,
        width: 100,
    },
    {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Name En',
        field: 'name_en',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Bild',
        field: 'image',
        width: 160,
        renderCell: (params) => {
            return <img className={"statistic-table-link"} width="100px" src={process.env.REACT_APP_IMAGE_URL + params.value} />
        }
    },
    {
        headerName: 'Prefix',
        field: 'prefix',
        flex: 2,
        width: 100
    },
    {
        headerName: 'Actions',
        flex: 2,
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NavLink className={"table-link"} to={`/productcharacterdetail/category?detail=${params.row['id']}`} title={params.value}><i className="fa-solid fa-pencil"></i> Bearbeiten </NavLink>
                </div>
            )
        }
    },
]

export const categoryColumns_en = [

    {
        headerName: "ID",
        field: "id",
        flex: 2,
        width: 100,
    },
    {
        headerName: 'Name',
        field: 'name',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Name En',
        field: 'name_en',
        flex: 2,
        width: 200,
    },
    {
        headerName: 'Image',
        field: 'image',
        width: 160,
        renderCell: (params) => {
            return <img className={"statistic-table-link"} width="100px" src={process.env.REACT_APP_IMAGE_URL + params.value} />
        }
    },
    {
        headerName: 'Prefix',
        field: 'prefix',
        flex: 2,
        width: 100
    },
    {
        headerName: 'Actions',
        flex: 2,
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NavLink className={"table-link"} to={`/productcharacterdetail/category?detail=${params.row['id']}`} title={params.value}><i className="fa-solid fa-pencil"></i> Bearbeiten </NavLink>
                </div>
            )
        }
    },
]