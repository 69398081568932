function Filter(props) {


    return (
        <>

        {
            props.data.map(function(filter,idx){
                for( let i = 0; i < props.products.length; i++) {
                    if(JSON.stringify(props.products[i]).indexOf(filter['name']) > -1) {
                        return (
                        <div key={idx}>
                            <input type="checkbox" value={filter['name']} id={props.filterKey + "-" + idx} name={props.filterKey} 
                                onChange={(e) => props.addFilter(e, props.filterKey, idx)} />
                            <label htmlFor={props.filterKey + "-" + idx}>{filter['name']}</label>
                        </div>
                        )
                        break;
                    }
                }
            })
        }
        </>
    )
}

export default Filter;



