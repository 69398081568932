import { useState, useEffect } from "react";
import FormItems from "./FormItems";
import axios from 'axios';
import { elementFromString } from "@tiptap/react";


function PerformancedataMenu(props) {
    //console.log(props);
    let [performanceData, setPerformanceData] = useState([...props.data]);
    let language = localStorage.getItem("kerion_language");

    

    useEffect(() => {
      setPerformanceData([...props.data]);
    }, [props.data]);

    function performanceDataChange(field, value, index = 0) {
      //console.log(performanceData, props.data);
      //console.log(field, value, index);

      let newIndexData = [...performanceData];
      newIndexData[index][field] = value;

      setPerformanceData(newIndexData);
      props.handleChange(newIndexData);
    }

    function toggleClick(e) {
      let next = e.target.parentNode.parentNode.parentNode.nextSibling;
      console.log(next, e.target);
      if(next){       
          e.target.style.transform =  (e.target.style.transform == 'rotate(180deg)') ? 'rotate(0)' : 'rotate(180deg)';
          next.style.display = (next.style.display == 'none') ? 'grid' : 'none';
      }
    }
    
    function addPerformanceData(e) {
      e.preventDefault();
      let newPerformanceData = [...performanceData];
      let obj = {};
      newPerformanceData.push(obj);

      console.log(newPerformanceData);
      setPerformanceData(newPerformanceData);
    }

    function deletePerformancedata(e, val) {
      console.log(val);

      if(val.id) {
        axios.post(process.env.REACT_APP_URL + 'deleteData.php?performancedata=true', val)
        .then(response =>  {
          console.log(response);

          if(response.data[0] == true){
            let elem = document.getElementById('performancedata-'+ val.id);
            elem.style.display = 'none';
          }  
            
        })
        .catch(error => console.log(error));
      }else {
        let elem = e.target.parentNode.parentNode.parentNode.parentNode;
        elem.style.display = 'none';
      }
    }

  return (
    <>
        {performanceData.length > 0 && (performanceData).map(function(val,index) { 
            return (
              <div id={"performancedata-" + val['id']} key={index}>
                <div className="performance_preview">
                  <div>
                    {Object.keys(val).map(function(key,index) { 
                        if(val[key]){

                          /*if(key == 'performance_price'){
                            return(
                              (val[key]).map(function(prices, ind){
                                 return <span key={ind}>{prices['name']} {global.config[key][language] + ' ' + prices['price']} €</span>;
                              })
                            )
                              
                          }else {*/
                            if(global.config[key] && global.config[key][language])
                              return <span key={index}>{global.config[key][language] + ' ' + val[key]} </span>
                            else
                              return <span key={index}>{key + ' ' + val[key]} </span>
                          //}
                        }
                    }) }
                    </div>
                    <div>
                      <div onClick={(e) => deletePerformancedata(e, val)}><i className="fas fa-trash" ></i></div>
                      <div onClick={toggleClick}><i className="fas fa-chevron-up" ></i></div>
                    </div>
                </div>
                <div className="performance_details">
                  <FormItems elements={props.elements} 
                      variationdata={props.variationdata}
                      handleChange={(field, value) => performanceDataChange(field, value, index)} 
                      data={val} 
                      type={props.type} />
                </div>
            </div>
            )
          }) 
        }
        <div className="newperformance">
          <button onClick={addPerformanceData}>Neue Leistung hinzufügen</button>
        </div>

    </>
  );
}

export default PerformancedataMenu;