import {useState} from 'react'; 
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
    return `${value}°C`;
  }

function MySlider(props) {

    const [value, setValue] = useState(props.data);

    const handleChange = (e, newValue, name) => {
        props.addSlider(newValue, name);
        setValue(newValue);
    };

    return (
        <>
            <Slider
                min={props.data[0]}
                max={props.data[1]}
                value={value}
                onChange={(e, newValue) => handleChange(e, newValue, props.slider)}
                valueLabelDisplay="on"
                getAriaValueText={valuetext}
            />
        </>
    )

}

export default MySlider;