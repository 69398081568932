import { useState, useEffect } from "react";
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

function Radiobutton(props) {
  const [val, setVal] = useState(props.data[props.config.field] ? props.data[props.config.field] : '');
  const [checkboxVal, setCheckboxVal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  let variationid = searchParams.get("detail");
  const [visible, setVisible] = useState(true);

  useEffect(() => { 

    /*if(props.variationdata)
    console.log(props.config.field, props.variationdata['lightoutput'])

    if((props.variationdata && props.config.field == 'ugr_indirect' && props.variationdata['lightoutput'] == 1) ||
      (props.variationdata && props.config.field == 'ugr' && props.variationdata['lightoutput'] == 2) ) {
        setVisible(false);
    }else {
        setVisible(true);
    }*/
  },[props.variationdata]);


  function changeVal(e) {
      console.log(e.target.value)
      setVal(e.target.value);
      props.onChange(props.config.field, e.target.value);
  }

  useEffect(() => { 
    if(props.config.databasetable) {
      axios.get(process.env.REACT_APP_URL + 'getSelectData.php?data='+props.config.databasetable)
      .then(response =>  {
        setCheckboxVal(response.data);
      })
      .catch(error => console.log(error));

    }   
  }, []);

  return (
    <> 
    {visible &&
    <div><label className={'labelcontainer type-' + props.config.type}>
        <span>{props.config.label}</span>
        <div className="inputcontainer">
        
        {props.config.type == 'radio' && checkboxVal &&
          checkboxVal.map(function( key ,index) {
            let checked=false;            
            if(props.data['cross_product_variation_' +props.config.field] && props.data['cross_product_variation_' + props.config.field][key.id]) checked=true;
              return (
                <div className="checkboxcontainer" key={index}>
                    <input 
                      type="radio"
                      placeholder={props.data[props.config.field]}
                      readOnly={props.config.readonly}
                      value={key.id}
                      name={props.config.field}
                      required={props.config.required}
                      onChange={(e) => changeVal(e)}
                      defaultChecked={props.data[props.config.field] == key.id}
                      id={props.config.field + '_' + key.id }
                    /> <label htmlFor={props.config.field + '_' + key.id }>
                      {key.name}
                </label>
              </div>)
            })
          }
        </div>
    </label>
    
    </div>    
    }
  </>
  )
}


export default Radiobutton;