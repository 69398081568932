import '../Css/main.css';
import Sidemenu from './Sidemenu';
import axios from 'axios';
import {useEffect, useState} from 'react';
import { useGridApiRef } from '@mui/x-data-grid';
import {Table} from './Tables/Table';
import Loading from './Helper/Loading';
import { deleteCookie } from './Helper/cookiefuntions';

function Offer() {
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  let [data, setData] = useState();
  let [myOffers, setMyOffers] = useState();
  const apiRefTable = useGridApiRef();

  let language = localStorage.getItem("kerion_language");
  let user = JSON.parse(localStorage.getItem('kerion_user'));

  if(!localStorage.getItem('kerion_user')) {
    localStorage.setItem('loggedIn', false);
    deleteCookie('kerion_login');
    window.location.href = '/login';
  }

  useEffect(() => {
    setLoading({'display': true, 'circles': true, 'dialog': false, 'error' : false});

    if(user['roles'].indexOf('ROLE_ADMIN') > -1) {
      axios.get(process.env.REACT_APP_URL + 'getData.php?offer=true')
      .then(response =>  {
        console.log(response)
        setData(response.data);
        setLoading({'display': false, 'circles': false, 'dialog': false, 'error' : false});
      })
      .catch(error => console.log(error));
    }else {
      axios.get(process.env.REACT_APP_URL + 'getData.php?offer=true&owner_id='+user['id'])
      .then(response =>  {
        console.log(response)
        setMyOffers(response.data);
        setLoading({'display': false, 'circles': false, 'dialog': false, 'error' : false});
      })
      .catch(error => console.log(error));
    }
  }, []);

  return (
    <div className='main'>
        <Loading
            loading={loading}         
            headline={{'success' : global.config.successheadline[language], 'error' : global.config.errorheadline[language]}}
            text={{'success' :global.config.productsuccesstext[language], 'error' : global.config.producterrortext[language]}}
            button={{'ok' : true}}
            handleOk={() => setLoading({'display': false, 'circles': false, 'dialog': false})} 
        />
        <Sidemenu />
        <section>
          <h1>{global.config.offer[language]}</h1>

          {myOffers && 
            <Table data={myOffers} mode="offer" tablebuttons={false} apiRef={apiRefTable} />
          }
          {data && 
            <Table data={data} mode="offer" tablebuttons={false} apiRef={apiRefTable} />
          }
        </section>
    </div>
  );
}

export default Offer;