import { useEffect, useState } from "react";
import { columns as productColumns, columns_en as productColumnsEN } from "./ProductConfig";
import { columns as productColumnsUser, columns_en as productColumnsUserEN } from "./ProductConfigUser";
import { columns as productColumnsUserV, columns_en as productColumnsUserVEN } from "./ProductConfigUserNotVerified";
import { columns as productVariationColumns, columns_en as productVariationColumnsEN } from "./ProductVariationsConfig"
import { columns as userColumns, columns_en as userColumnsEN} from "./UsersConfig"
import { columns as userLoginColumns, columns_en as userLoginColumnsEN} from "./UserLoginConfig"
import { columns as offerColumns, columns_en as offerColumnsEN } from "./OfferConfig"
import { criColumns, colorColumns, opticColumns, lighttemperatureColumns, 
        dimmableColumns, woodoptionColumns, woodsurfaceColumns, categoryColumns, categoryColumns_en, ugrColumns} from "./ProductCharacteristicsConfig";
import Box from '@mui/material/Box';
import { DataGrid,  GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector, GridToolbar,
    GridToolbarQuickFilter} from '@mui/x-data-grid';
import { deDE, enUS } from '@mui/x-data-grid/locales';

export const modes = {
    "product_de": productColumns,
    "product_en" : productColumnsEN,

    "productvariations_de": productVariationColumns,
    "productvariations_en": productVariationColumnsEN,
    
    "cri_de": criColumns,
    "cri_en": criColumns,

    "color_de": colorColumns,
    "color_en": colorColumns,

    "user_de": userColumns,
    "user_en": userColumnsEN,

    "userlogin_de": userLoginColumns,
    "userlogin_en": userLoginColumnsEN,

    "optic_de": opticColumns,
    "optic_en": opticColumns,

    "lighttemperature_de": lighttemperatureColumns,
    "lighttemperature_en": lighttemperatureColumns,

    "dimmable_de": dimmableColumns,
    "dimmable_en": dimmableColumns,

    "woodoption_de": woodoptionColumns,
    "woodoption_en": woodoptionColumns,

    "woodsurface_de": woodsurfaceColumns,
    "woodsurface_en": woodsurfaceColumns,

    "offer_de": offerColumns,
    "offer_en": offerColumnsEN,

    "category_de": categoryColumns,
    "category_en": categoryColumns_en,

    "ugr_de": ugrColumns,
    "ugr_en": ugrColumns,

    "productUser_de" : productColumnsUser,
    "productUser_en" : productColumnsUserEN,

    "productUserV_de" : productColumnsUserV,
    "productUserV_en" : productColumnsUserVEN
}

export function Table(props) {
    const [selectionModel, setSelectionModel] = useState([1]);
    let language = localStorage.getItem("kerion_language");


    const columns = modes[props.mode + '_' + language];

    if (!columns) {
        return <div>Unknown mode: {props.mode}</div>
    }

    function getSelectedRows() {
        props.onDelete(selectionModel)
    }


    return (
        <>
            <div className="table-buttons top">
                
                <div className="table-buttons">
                {props.tablebuttons && 
                    <>
                    {<button onClick={() => getSelectedRows()}><i className="fa-solid fa-trash"></i>{global.config.deleterows[language]}</button>}
                    {!props.link && <a className="button" href={"/productcharacterdetail/"+props.mode+"?new=true"}><i className="fa-solid fa-plus"></i>Neue {props.mode} hinzufügen</a>}
                    {props.link && <a className="button" href={props.link}><i className="fa-solid fa-plus"></i>{props.linktext}</a>}
                    </>
                }
                {props.deletebutton && 
                    <>
                    {<button onClick={() => getSelectedRows()}><i className="fa-solid fa-trash"></i>{global.config.deleterows[language]}</button>}
                    </>
                }
                {/* props.addtooffer && 
                    <>
                    <button onClick={() => props.onAdd(selectionModel)}><i className="fa-solid fa-plus"></i>Zum Angebot hinzufügen</button>
                    </>
                */}
                </div>
            </div>
        
        <div id="table" className={props.mode} >
            {(props.mode == 'product' || props.mode == 'productUser' || props.mode == 'productUserV' || props.mode == 'userlogin') &&
                <DataGrid 
                    localeText={language == 'de' ? deDE.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
                    apiRef={props.apiRef}
                    getRowId={(data) => data.id}
                    rowHeight={70}
                    rows={props.data}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    onCellClick={(data) => props.cellClicked(data)}
                    /*onColumnVisibilityModelChange={(data) => props.tableChanged(data.id)}
                    onFilterModelChange={(data) => props.tableChanged(data.id)}*/
                    slotProps={{
                        toolbar: {
                        showQuickFilter: true,
                        },
                    }}
                />
            }
            {props.mode != 'user' && props.mode != 'userlogin' && props.mode != 'product' && props.mode != 'productUser' && props.mode != 'productUserV' && props.mode != 'offer' && !props.addtooffer && !props.removefromoffer &&
                <DataGrid 
                    localeText={language == 'de' ? deDE.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
                    apiRef={props.apiRef}
                    getRowId={(data) => data.id}
                    rowHeight={70}
                    rows={props.data}
                    columns={columns}
                    checkboxSelection
                    onRowSelectionModelChange={(ids) => setSelectionModel(ids)}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                        showQuickFilter: true,
                        },
                    }}
                /> 
            }
            {props.mode == 'user' && 
                <DataGrid 
                    localeText={language == 'de' ? deDE.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
                    apiRef={props.apiRef}
                    getRowId={(data) => data.id}
                    rowHeight={70}
                    rows={props.data}
                    columns={columns}
                    checkboxSelection
                    onRowSelectionModelChange={(ids) => setSelectionModel(ids)}
                    disableMultipleRowSelection={true}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                        showQuickFilter: true,
                        },
                    }}
                /> 
            }
            {(props.mode == 'offer' ) &&
                <DataGrid 
                    localeText={language == 'de' ? deDE.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
                    apiRef={props.apiRef}
                    getRowId={(data) => data.id}
                    rowHeight={70}
                    rows={props.data}
                    columns={columns}
                    onRowSelectionModelChange={(ids) => setSelectionModel(ids)}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                        showQuickFilter: true,
                        },
                    }}
                /> 
            }
        </div>
    </>)
       
}


  