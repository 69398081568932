import { NavLink } from "react-router-dom";

export const columns = [
    {
        headerName: "Anr",
        field: "anr",
        width: 150,
        /*renderCell: (params) => {
            return <NavLink className={"statistic-table-link"} to={`/trucktable/${params.row['clientnumber']}`} title={params.value}>{params.value}</NavLink>
        }*/
    },
    {
        headerName: 'Preis',
        field: 'price',
        width: 150,
        renderCell: (params) => {
            if(params.row['price'])
                return params.row['price'].toFixed(2) + ' €';
            else
                return (params.row['oldprice']) ? params.row['oldprice'].toFixed(2) + ' €' : 0.00 + ' €';
        }
    },
    {
        headerName: 'Variations Name',
        field: 'product_variation_name',
        width: 170,
        renderCell: (params) => {
            return <NavLink className={"statistic-table-link"} to={`/productvariations/detail?detail=${params.row['variation_id']}`} title={params.value}>{params.value}</NavLink>
        }
    },
    {
        headerName: 'Variations Bild',
        field: 'image',
        width: 160,
        renderCell: (params) => {
            return <img className={"statistic-table-link"} width="100px" src={process.env.REACT_APP_IMAGE_URL + 'small/' + params.value}
                onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src= process.env.REACT_APP_IMAGE_URL + params.value;
              }}
             />
        }
    },
    {
        headerName: 'Datenblatt',
        field: 'datenblatt',
        width: 160,
        renderCell: (params) => {
            return <button className={"statistic-table-link datasheet"} width="100px">Datenblatt</button>
        }
    },
    {
        headerName: 'Datenblatt EN',
        field: 'datenblatt_en',
        width: 160,
        renderCell: (params) => {
            return <button className={"statistic-table-link datasheet"} width="100px">Datasheet (EN)</button>
        }
    },
    {
        headerName: 'Farbe',
        field: 'colorname',
        width: 100,
    },
    {
        headerName: 'Optik',
        field: 'opticname',
        width: 100,
    },
    {
        headerName: 'Dimmbar',
        field: 'dimmablename',
        width: 100,
    },
    {
        headerName: 'Lichttemperatur',
        field: 'lighttemperaturename',
        width: 100,
    },
    {
        headerName: 'Cri',
        field: 'criname',
        width: 100,
    },
    {
        headerName: 'Watt',
        field: 'watt',
        width: 100,
    },
    {
        headerName: 'Lumen',
        field: 'lumen',
        width: 100,
    },
    {
        headerName: 'Lumen (indirect)',
        field: 'lumen_indirect',
        width: 100,
    },
    {
        headerName: "Anschluss",
        field: "power_supply",
        width: 100,
    },
    {
        headerName: 'Maße (l x b x h)',
        field: 'length_mm',
        width: 150,
        renderCell: (params) => {
            return <span>{params.row['length_mm']} x {params.row['width_mm'] ?? ''} x {params.row['height_mm'] ?? ''} mm </span>
        }
    },
    {
        headerName: 'Durchmesser',
        field: 'diameter_mm',
        width: 150,
    },
    {
        headerName: 'Gewicht',
        field: 'weightname',
        width: 150,
    },
    {
        headerName: 'Deckenausschnitt',
        field: 'outlinename',
        width: 150,
    },
    {
        headerName: 'UGR',
        field: 'ugr_indirect',
        width: 150,
    },
    {
        headerName: 'Abdeckung',
        field: 'ugrname',
        width: 150,
    },
    {
        headerName: 'Holzoption',
        field: 'woodoptionname',
        width: 150,
    },
    {
        headerName: 'Holzoberfläche',
        field: 'woodsurfacename',
        width: 150,
    },
    
]

export const columns_en = [
    {
        headerName: "Anr",
        field: "anr",
        width: 150,
        /*renderCell: (params) => {
            return <NavLink className={"statistic-table-link"} to={`/trucktable/${params.row['clientnumber']}`} title={params.value}>{params.value}</NavLink>
        }*/
    },
    {
        headerName: 'Price',
        field: 'price',
        width: 150,
        renderCell: (params) => {
            if(params.row['price'])
                return params.row['price'] + ' €';
            else
                return (params.row['oldprice']) ? params.row['oldprice'] + ' €' : 0 + ' €';
        }
    },
    {
        headerName: 'Variation Name',
        field: 'product_variation_name',
        width: 170,
        renderCell: (params) => {
            return <NavLink className={"statistic-table-link"} to={`/productvariations/detail?detail=${params.row['variation_id']}`} title={params.value}>{params.value}</NavLink>
        }
    },
    {
        headerName: 'Variation Image',
        field: 'image',
        width: 160,
        renderCell: (params) => {
            return <img className={"statistic-table-link"} width="100px" src={process.env.REACT_APP_IMAGE_URL + 'small/' + params.value} 
                onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src= process.env.REACT_APP_IMAGE_URL + params.value;
              }
            }/>
        }
    },
    {
        headerName: 'Datasheet EN',
        field: 'datenblatt_en',
        width: 160,
        renderCell: (params) => {
            return <button className={"statistic-table-link datasheet"} width="100px">Datasheet (EN)</button>
        }
    },
    {
        headerName: 'Datasheet DE',
        field: 'datenblatt',
        width: 160,
        renderCell: (params) => {
            return <button className={"statistic-table-link datasheet"} width="100px">Datasheet (DE)</button>
        }
    },
    
    {
        headerName: 'Color',
        field: 'colorname_en',
        width: 100,
    },
    {
        headerName: 'Optic',
        field: 'opticname',
        width: 100,
    },
    {
        headerName: 'Dimmable',
        field: 'dimmablename',
        width: 100,
    },
    {
        headerName: 'Lighttemperature',
        field: 'lighttemperaturename',
        width: 100,
    },
    {
        headerName: 'Cri',
        field: 'criname',
        width: 100,
    },
    {
        headerName: 'Watt',
        field: 'watt',
        width: 100,
    },
    {
        headerName: 'Lumen',
        field: 'lumen',
        width: 100,
    },
    {
        headerName: 'Lumen (indirect)',
        field: 'lumen_indirect',
        width: 100,
    },
    {
        headerName: "Power Supply",
        field: "power_supply",
        width: 100,
    },
    {
        headerName: 'Dimensions (l x b x h)',
        field: 'length_mm',
        width: 150,
        renderCell: (params) => {
            return <span>{params.row['length_mm']} x {params.row['width_mm'] ?? ''} x {params.row['height_mm'] ?? ''} mm </span>
        }
    },
    {
        headerName: 'Diameter',
        field: 'diameter_mm',
        width: 150,
    },
    {
        headerName: 'Weight',
        field: 'weightname',
        width: 150,
    },
    {
        headerName: 'Outline',
        field: 'outlinename',
        width: 150,
    },
    {
        headerName: 'UGR',
        field: 'ugr_indirect',
        width: 150,
    },
    {
        headerName: 'Abdeckung',
        field: 'ugrname',
        width: 150,
    },
    {
        headerName: 'Woodoption',
        field: 'woodoptionname',
        width: 150,
    },
    {
        headerName: 'Woodsurface',
        field: 'woodsurfacename',
        width: 150,
    },
    
]

export const excelColumns = [
    {
        headerName: "Artikelnummer",
        field: "anr",
    },
    {
        headerName: 'Variations Name',
        field: 'product_variation_name',
    },
    {
        headerName: 'Beschreibung',
        field: 'description',
    },
    {
        headerName: 'Description',
        field: 'description_en',
    },
    {
        headerName: 'Lieferumfang',
        field: 'delivery_scope_de',
    },
    {
        headerName: 'Scope of delivery',
        field: 'delivery_scope_en',
    },

    {
        headerName: 'Eigenschaften',
        field: 'properties',
    },
    {
        headerName: 'Eigenschaften EN',
        field: 'properties_en',
    },
    {
        headerName: 'Datenblatt',
        field: 'datenblatt',
    },
    {
        headerName: 'Datenblatt EN',
        field: 'datenblatt_en',
    },
    {
        headerName: 'Preis',
        field: 'price',
    },
]