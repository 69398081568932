import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import { EditorProvider, useCurrentEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import {useState, useEffect} from 'react'

const MenuBar = () => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null
  }

  return (
    <>
      <button
        onClick={(e) => {e.preventDefault(); editor.chain().focus().toggleBold().run()}}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <i className="fa-solid fa-bold"></i>
      </button>
      <button
        onClick={(e) => {e.preventDefault(); editor.chain().focus().toggleItalic().run()}}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleItalic()
            .run()
        }
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        <i className="fa-solid fa-italic"></i>
      </button>
      {/*<button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleStrike()
            .run()
        }
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        strike
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleCode()
            .run()
        }
        className={editor.isActive('code') ? 'is-active' : ''}
      >
        code
      </button>
      <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
        clear marks
      </button>
      <button onClick={() => editor.chain().focus().clearNodes().run()}>
        clear nodes
      </button>*/}
      <button
        onClick={(e) => {e.preventDefault(); editor.chain().focus().setParagraph().run()}}
        className={editor.isActive('paragraph') ? 'is-active' : ''}
      >
        <i className="fa-solid fa-paragraph"></i>
      </button>
      <button
        onClick={(e) => {e.preventDefault();editor.chain().focus().toggleHeading({ level: 1 }).run()}}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
      >
        h1
      </button>
      <button
        onClick={(e) => {e.preventDefault();editor.chain().focus().toggleHeading({ level: 2 }).run()}}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        h2
      </button>
      <button
        onClick={(e) => {e.preventDefault();editor.chain().focus().toggleHeading({ level: 3 }).run()}}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
      >
        h3
      </button>
      <button
        onClick={(e) => {e.preventDefault(); editor.chain().focus().toggleHeading({ level: 4 }).run()}}
        className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
      >
        h4
      </button>
      <button
        onClick={(e) => {e.preventDefault();editor.chain().focus().toggleHeading({ level: 5 }).run()}}
        className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
      >
        h5
      </button>
      <button
        onClick={(e) => {e.preventDefault();editor.chain().focus().toggleHeading({ level: 6 }).run()}}
        className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
      >
        h6
      </button>
    </>
  )
}

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
]

export default (props) => {
  const [val, setVal] = useState(props.data[props.config.field]);
  let language = localStorage.getItem('kerion_language');


  function changeVal(text) {
    setVal(text);
    props.onChange(props.config.field, text);
  }

  return (
    <div className='labelcontainer'>
      <label>
      {(language == 'de' || !props.config.label_en) &&
          <span>{props.config.label}</span>
        }
        {language == 'en' && props.config.label_en &&
          <span>{props.config.label_en}</span>
        }
      </label>
      <div className="texteditor">
        {props.config.type != 'textarea' &&
          <EditorProvider 
          slotBefore={<MenuBar />}
          extensions={extensions} 
          content={val}
          onUpdate={({ editor }) => {changeVal(editor?.getHTML());}}
          ></EditorProvider>
        }
        {props.config.type == 'textarea' &&
          <EditorProvider 
          extensions={extensions} 
          content={val}
          onUpdate={({ editor }) => {changeVal(editor?.getHTML());}}
          ></EditorProvider>
        }
        </div>
    </div>
  )
}