import { useState } from 'react';
import '../Css/main.css';
import Loading from './Helper/Loading';
import {Elements} from './Tables/RegistrationConfig';
import axios from 'axios';
import FormItems from './Details/FormItems';
import Sidemenu from './Sidemenu';
import { deleteCookie } from './Helper/cookiefuntions';
import { useSearchParams } from 'react-router-dom';
import ForgotPwd from './ForgotPwd';


function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [registration, setRegistration] = useState([false, 0]);
  const [registerData, setRegisterData] = useState({country: 'aut' })
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [forgotpwd, setForgotpwd] = useState(searchParams.get("resetpwd") ?? false);

  /* Language */
  let language = localStorage.getItem('kerion_language') ?? 'de';
  const [dialogText, setDialogText] = useState({text: global.config.loginsuccesstext[language]});

  if(!localStorage.getItem('kerion_user')) {
    localStorage.setItem('loggedIn', false);
    deleteCookie('kerion_login');
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();

    if(!username) {
      setError({ 'error' : true, 'email' : true});
    }else if(!password) {
      setError({'error' : true, 'password' : true});
    }else {
      setLoading({'display': true, 'circles': true, 'dialog': false, 'error' :false});

      axios.post(process.env.REACT_APP_URL + 'getUser.php?login=true', {'usern' : username, 'pwd' : password})
        .then(response =>  {
          if(response.data.length == 1){

              if(response.data[0].email_verified == 1 && response.data[0].is_active == 1) {
                localStorage.setItem('loggedIn', true);

                if(response.data[0].country == 'aut' || response.data[0].country == 'Österreich' || 
                  response.data[0].country == 'de' || response.data[0].country == 'Deutschland')
                  localStorage.setItem("kerion_language", 'de');
                else localStorage.setItem("kerion_language", 'en');

                setCookie('kerion_login', username, 7);
                localStorage.setItem('kerion_user', JSON.stringify(response.data[0]));

                //let href= window.location.href.split("/").pop();

                if( window.location.href.indexOf('login') >= 0)
                  window.location.href='/';
                else
                  window.location.reload();

              }else if(response.data[0].is_active == 0) { 
                setError({error : true, 'notactive' : true});
                setLoading({'display': false, 'circles': false, 'dialog': false, 'error' : false});
              }
              else { 
                setError({error : true, 'verified' : true});
                setDialogText({text: global.config.loginerrortext[language]});
                setLoading({'display': false, 'circles': false, 'dialog': false, 'error' : false});
              }
              
          }else {
              setDialogText({text: global.config.loginerrortext[language]});
              setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : true});
          }
              
        })
        .catch(error => console.log(error));
    }
  };


  function registerUser() {

      axios.post(process.env.REACT_APP_URL + 'getUser.php?register=true&lang='+language, registerData)
        .then(response =>  {
          console.log(response);
          if(response.data[0]){
              setDialogText({text : global.config.registersuccesstext[language]});
              setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : false});
          }else {
              setDialogText({text : global.config.registererrortext[language]});
              setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : true});
          }
              
        })
        .catch(error => console.log(error));
      
  };

  function setData(name, value) {
    setRegisterData({...registerData, [name] : value});
  }

  function handleSubmitRegister(event) {
    event.preventDefault();
    setError({error : false});

    let curerror = false;
    let errorObj = {'error' : false};

    if(!registerData.fullname) {
      errorObj = {...errorObj, 'error' : true, 'fullname' : true};
      setError(errorObj);
    }
    if(registerData.email ) {
      axios.post(process.env.REACT_APP_URL + 'getUser.php?name='+registerData.email, {'mail' : registerData.email})
        .then(response =>  {

          if(response.data[0] != 0){
            errorObj = {...errorObj, error : true, 'username' : true};
            curerror = true;
          }
          if(!registerData.password || !registerData.pwd2 || registerData.password != registerData.pwd2) {
            curerror = true;
            errorObj = {...errorObj, 'error' : true, 'pwd' : true};
          }
          if(!privacyPolicy) {
            curerror = true;
            errorObj = {...errorObj, 'error' : true, 'privacy' : true};
          }
          setError(errorObj);

          console.log(curerror, errorObj);

          if(!curerror) registerUser();;
        })
        .catch(error => console.log(error));
    }else {
      errorObj = {...errorObj, 'error' : true, 'email' : true};
      setError(errorObj);
    }
  }

  function checkPwd(pwd) {
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    var format2 = /[0-9]/;

    if(pwd.length < 8 || !format.test(pwd) || !format2.test(pwd) ){
      setError({error : true, 'errorChar' : true});
    }else 
      setError({error : false});
    
  }

  function handleSubmitPwd(event) {
    event.preventDefault();

    if(username) {
      //setLoading({'display': true, 'circles': true, 'dialog': false, 'error' :false});

      axios.post(process.env.REACT_APP_URL + 'getUser.php?name='+username, {mail : username})
        .then(response =>  {

          if(response.data[0] != 0){
              
            axios.post(process.env.REACT_APP_URL + 'sendEmail.php?newpwd=true&lang=' + language, {mail : username, id: response.data[1][0].id})
            .then(response =>  {
              console.log(response)
              if(response.data) {
                setError({'error' : true, 'pwdsuccess' : true});
              }else {
                setError({'error' : true, 'pwderror' : true});
              }
              
            }).catch(error => console.log(error));;


          }else {
              setDialogText({text : global.config.emailerrortext[language]});
              setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : true});
          }
              
        })
        .catch(error => console.log(error));
    }else {
       setError({'error' : true, 'email' : true});
    }

    
  }

  function handleOk() {
    window.location.reload();
  }

  return (
    <section className='loginsection'>
      <Sidemenu></Sidemenu>
      {!forgotpwd &&
      <>
      <Loading
            loading={loading}         
            headline={{'success' : global.config.successheadline[language], 'error' : global.config.errorheadline[language]}}
            text={{'success' : dialogText.text, 'error' : dialogText.text}}
            button={{'ok' : true}}
            handleOk={handleOk} 
        />
        
        <div id="login" className={`popup ${registration[0] ? `register page-${registration[1]}` : ''}`}>
          <a href="/"><img src="https://www.kerionlighting.com/wp-content/uploads/2024/01/kerion-logo-dark.png"  /></a>     
            {!registration[0] && registration[1] == 0 &&
              <form onSubmit={handleSubmit}>
                <h1>Login</h1>
                <label>
                    <input placeholder="Email" type="email" value={username} onChange={(e) => setUsername(e.target.value)}/>
                    {error.email && <span className="errortext">{global.config.mandatory[language]}</span>}
                </label>
                <label>
                    <input placeholder={global.config.password[language]} type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    {error.password && <span className="errortext mb-1">{global.config.mandatory[language]}</span>}
                </label>

                {error.verified && <span className="errortext">{global.config.emailnotverified[language]}</span>}
                {error.notactive && <span className="errortext">{global.config.loginerroractivetext[language]}</span>}
              
                <a href="#" onClick={() => setRegistration([true, 0])}>{global.config.register[language]}</a>
                
                <a href="#" onClick={() => setRegistration([false, 1])}>{global.config.forgotpwd[language]}</a>
                <input type="submit" value={global.config.login[language]} />
            </form>
            }
            { /* ------------- PASSWORT ZURÜCKSETZEN -------------- */}

            {!registration[0] && registration[1] == 1 &&
              <form onSubmit={handleSubmitPwd}>
                <h1>{global.config.resetpwd[language]}</h1>
                {!error.pwdsuccess && ! error.pwderror &&
                <>
                  <label>
                    <input placeholder="Email" type="email" value={username} onChange={(e) => setUsername(e.target.value)}/>
                    {error.email && <span className="errortext">{global.config.mandatory[language]}</span>}
                    {error.emailNotFound && <span className="errortext">{global.config.mailnotindb[language]}</span>}
                </label>  
                              
                <input type="submit" value={global.config.resetpwd[language]} /></>

                }{error.error && error.pwdsuccess &&
                  <span className="successtext">{global.config.mailresetpwd[language]}</span>
                }
                {error.error && error.pwderror &&
                  <span className="errortext">{global.config.pwdcouldntreset[language]}</span>
                }
                
                <a href="#" onClick={() => setRegistration([false, 0])}>{global.config.back[language]}</a>
            </form>
            }

            { /* ------------- REGISTRIERUNG -------------- */}

            {registration[0] &&
              <form className="registration" onSubmit={handleSubmitRegister}>
                <h1>{global.config.becomeB2Bpartner[language]}</h1>
                {registration[1] == 0 &&
                <> 
                <label>
                    <input placeholder="Name*" type="text" name="name" required value={registerData.fullname} onChange={(e) => setData('fullname', e.target.value)} />
                    {error.fullname && <span className="errortext">{global.config.mandatory[language]}</span>}
                </label>
                <label>
                    <input placeholder="Email*" type="text" name="email" required value={registerData.email} onChange={(e) => setData('email', e.target.value)} />
                    {error.username && <span className="errortext">{global.config.alreadyregistered[language]}</span>}
                    {error.email && <span className="errortext">{global.config.mandatory[language]}</span>}
                </label>
                <label>
                    <input placeholder={global.config.phone[language] + '*'} required type="text" name="phone" value={registerData.tel} onChange={(e) => setData('phone', e.target.value)} />
                </label>
                <label>
                    <input placeholder={global.config.password[language] + '*'} required type="password" name="pwd" value={registerData.password} 
                      onKeyUp={(e) => checkPwd(e.target.value)} 
                      onChange={(e) => {setData('password', e.target.value); }} />
                    {error.error && error.errorChar && <span className="errortext">{global.config.passwordcheck[language]}</span>}
                </label>
                <label>
                    <input placeholder={global.config.passwordrepeat[language] + '*'} type="password" name="pwd2" value={registerData.pwd2} onChange={(e) => setData('pwd2', e.target.value)} />
                    {error.error && error.pwd && <span className="errortext">{global.config.unequal[language]}</span>}
                </label>

                <div className="newsletterinput newsletter">
                  <FormItems elements={Elements} handleChange={setData} data={{}} type='new' />
                </div>  
                <div className="newsletterinput">
                  <div className="checkboxcontainer privacy">
                      <input type='checkbox' placeholder='datenschutz' id="datenschutz"
                        name='datenschutz' required
                        onChange={(e) => {(e.target.value == 'on') ?  setPrivacyPolicy(true) : setPrivacyPolicy(false)}}
                        defaultChecked={privacyPolicy}
                      /> 
                      <label htmlFor='datenschutz'>
                        <span className="privacy">Ich habe den <a href="https://www.kerionlighting.com/datenschutzerklaerung" target="_blank">Datenschutz</a> gelesen und akzeptiere diesen</span>
                    </label>
                    {error.error && error.privacy &&
                      <span className="errortext">{global.config.privacyaccept[language]}</span>
                    }
                  </div>
                </div>


                <a href="#" onClick={() => setRegistration([false, 0])}>{global.config.alreadymember[language]}</a>
                  <div className="buttoncontainer">
                    <input type="submit" onClick={handleSubmitRegister} value={global.config.register[language]} />
                  </div>
                  

                {/*<a href="#" onClick={() => setRegistration([false, 0])}>{global.config.alreadymember[language]}</a>
                <button type="button" onClick={() => checkRegistrationPart()} >{global.config.continue[language]}</button>*/}

                </>
              }{/*registration[1] == 1 &&
                <> 
                
                  <div className="registrationinputs">
                    <FormItems elements={Elements} handleChange={setData} data={{}} type='new' />
                  </div>

                  <a href="#" onClick={() => setRegistration([false, 0])}>{global.config.alreadymember[language]}</a>
                  <div className="buttoncontainer">
                    <button onClick={() => setRegistration([true, 0])} ><i className="fas fa-chevron-left"></i>{global.config.back[language]}</button>
                    <input type="submit" value={global.config.register[language]} />
                  </div>
                  
                </>
              */}
            </form>
            }
        </div>
        </> }
        {forgotpwd && 
          <ForgotPwd searchParams={searchParams} onSuccess={() => setForgotpwd(false)} />
        }
    </section>
  );
}


function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


export default Login;