import { useGridApiRef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import '../Css/main.css';
import Sidemenu from './Sidemenu';
import { useParams } from 'react-router-dom';
import {Table} from './Tables/Table';
import axios from 'axios';
import Loading from './Helper/Loading';
import { deleteCookie } from './Helper/cookiefuntions';



function Productcharacteristics() {
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  let { character } = useParams();
  const [data, setData] = useState([]);
  const apiRefTable = useGridApiRef();

  let language = localStorage.getItem("kerion_language");

  if(!localStorage.getItem('kerion_user')) {
    localStorage.setItem('loggedIn', false);
    deleteCookie('kerion_login');
    window.location.href = '/login';
  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_URL + 'getData.php?character='+character)
      .then(response =>  {
        console.log(response)
        setData(response.data);
      })
      .catch(error => console.log(error));
  }, [character]);


  function deleteCharacter(ids) {
    setLoading({'display': true, 'circles': true, 'dialog': false, 'error' :false});

    axios.post(process.env.REACT_APP_URL + 'deleteData.php?character='+character, {'ids' : ids})
      .then(response =>  {
        console.log(response);
        if(response.data[0] == true) 
          setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : false});
        else
          setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : true});
      })
      .catch(error => console.log(error));
  }

  function handleOk() {
    window.location.reload();
  }

  return (
    <div className="main">
      <Loading
            loading={loading}         
            headline={{'success' : global.config.successheadline[language], 'error' : global.config.errorheadline[language]}}
            text={{'success' :global.config.productsuccesstext[language], 'error' : global.config.producterrortext[language]}}
            button={{'ok' : true}}
            handleOk={handleOk} 
        />
        <Sidemenu/>
        <section>
            <h1>Productcharacteristics <span>{character}</span></h1>
            
          
          {data && 
            <Table onDelete={deleteCharacter} tablebuttons={true} data={data} mode={character} apiRef={apiRefTable} />
          }
      
        </section>
    </div>
  );
}

export default Productcharacteristics;