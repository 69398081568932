import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../Css/main.css';
import {Performance, VariationCharacters, additionalVariationCharacters, Elements, Images} from './ProductvariationDetailConfig';
import Sidemenu from '../Sidemenu';
import axios from 'axios';
import FormItems from './FormItems';
import PerformancedataMenu from './PerformancedataMenu';
import Loading from '../Helper/Loading';
import CombinationTable from './CombinationTable';


function ProductvariationDetail() {
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [files, setFiles] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  let variationid = searchParams.get("detail");

  const [newItem, setNewItem] = useState([(searchParams.get("new") ? true : false), false]);
  const [activePage, setActivePage] = useState(0);

  const formRef = useRef(null);
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  let language = localStorage.getItem("kerion_language");

  if(!localStorage.getItem('kerion_user')) {
    window.location.href = '/login';
  }

  useEffect(() => {
    let detail = searchParams.get("detail");
    let newSite = searchParams.get("new");

    if(!newSite && !newItem[1] && detail) {
      axios.get(process.env.REACT_APP_URL + 'getData.php?variations=true&detail='+variationid)
      .then(response =>  {
        console.log(response)
        setOriginalData(response.data[0]);
        setData(response.data[0]);
        getPerformanceData();
      })
      .catch(error => console.log(error));
    }else if(newSite) {
      /* ------- DEFAULT DATA -------*/

      setNewItem([true,false]);
      
      if(Object.keys(data).length)
        window.location.reload();
      
        setData({
          visible : true, 
          operatingdevice : true, 
          cross_product_variation_color : {2 : true, 3: true},
          cross_product_variation_lighttemperature: {1:true,2:true},
          cross_product_variation_cri : {1:true,2:true},
          cross_product_variation_dimmable : {1:true,2:true},
          cross_product_variation_optic: {10:true},
          voltage_from: 220,
          voltage_to:240,
          hz_from: 50,
          hz_to: 60,
          lightoutput: 3,
          product_category_id : 1,
          ambient_temperature: '-20 bis +45 °C',
          lifespan: 5000,
          material: 'Aluminium'
        })
    }
    
  }, [searchParams.get("new")]);

  function getPerformanceData() {
    if(!newItem[0]) {
      axios.get(process.env.REACT_APP_URL + 'getData.php?variations=performance&detail='+variationid)
        .then(response =>  {
          console.log(response)

          let performancedata = [...response.data];
          setPerformanceData(performancedata);
        })
        .catch(error => console.log(error));
    }
  }

  

  const handleSubmit = (event) => {
    event.preventDefault();
    const isFormValid = formRef.current.checkValidity();
    
   if((newItem[0] || newItem[1]) && activePage < 4 && isFormValid) {
      setActivePage(activePage + 1)
    }else if (isFormValid) {
      setLoading({'display': true, 'circles': true, 'dialog': false, 'error' :false});

      /*--------- Only Update new Itemns ----- */
      if(Object.keys(originalData).length) {
        var newdata = {...data};
        newdata = Object.keys(data).filter((key) =>  data[key] !== originalData[key] || key != 'id' ).reduce((newObj, key)=>{
          newObj[key] = data[key];
          return newObj;
        }, {});
      }

      console.log(data, performanceData);
      
      let url = process.env.REACT_APP_URL + 'setVariation.php?new=true';
      if(!newItem[0] && !newItem[1]) url= process.env.REACT_APP_URL + 'setVariation.php?variation='+variationid;
      
      console.log({'variationdata' : newdata ? newdata : data, 'performanceData' : performanceData});

      const fetchData = async(url) =>{
        return await axios.post(url, {'variationdata' : newdata ? newdata : data, 'performanceData' : performanceData}
        ).then(response =>  {
            console.log(response);
            return response.data;
        })
      }
      
      fetchData(url).then( response => {
          console.log(response);
            
          if(Object.keys(files).length > 0) {
              if(newItem[1] || newItem[0])
                url= process.env.REACT_APP_URL + 'setVariation.php?variation='+ response[1];

              const formData = new FormData();

              Object.keys(files).map( (key, value) => {
                if(key == 'product_gallery') {
                  files[key].map((val, index) => {
                    formData.append('gallery_' + index, val);
                  })
                }
                else 
                  formData.append(key, files[key]);
              })
              
              console.log(url);
              console.log(files, formData);

              const setFiles = async(url) =>{
                return await axios.post(url, formData
                ).then(response =>  {
                    console.log(response.data);
                    
                    if(response.data[0] == true) 
                      setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : false});
                    else
                      setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : true});

                    return response.data;
                })
              }

              let success = setFiles(url);
              
          }else {
            if(response[0] == true) 
              setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : false});
            else
              setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : true});
          }
          
      });
     } 
  }

  function handlePerformanceChange(data) {
    setPerformanceData(data);
  }
  
  function handleChange(key, value, type = 'text')  {
    if(type == 'files') setFiles({...files, [key]: value});
    else if(typeof value === 'object') {
      let newdata = {...data};
      newdata[key] = {...newdata[key], ...value};
      setData(newdata);
    }
    else setData({...data, [key]: value});

    console.log(type);
  }
  
  function handleOk() {
    if(!newItem[0] && !newItem[1])
      setLoading({'display': false, 'circles': false, 'dialog': false, 'error' : false});
    else if (newItem[1])
      window.location.href= "/productvariations";
    else
      window.location.reload();
  }
  
  function duplicateVariation() {
    let newData = {...data};
    newData.id = '';
    newData.product_variation_name = '';

    setData(newData);
    setNewItem([false, true]);
  }


  return (
    <div className="main">
        <Loading
            loading={loading}         
            headline={{'success' : global.config.successheadline[language], 'error' : global.config.errorheadline[language]}}
            text={{'success' :global.config.productvsuccesstext[language], 'error' : global.config.productverrortext[language]}}
            button={{'ok' : true}}
            handleOk={handleOk} 
        />
        

        <Sidemenu/>
        <section>
          {newItem[1] && 
            <h1>{global.config.newvariation[language]}</h1>
          }{!newItem[1] &&
            <h1>{global.config.productvariation[language]} {variationid} {data && data.product_variation_name}</h1>
          }

          {!newItem[1] &&  !newItem[0] && 
            <div className='tabs'>
                <button className={((activePage == 0) ? 'active' : '')} onClick={() => setActivePage(0)}>Allgemein</button>
                <button className={((activePage == 1) ? 'active' : '')} onClick={() => setActivePage(1)}>Eigenschaften</button>
                <button className={((activePage == 2) ? 'active' : '')} onClick={() => setActivePage(2)}>Eigenschaften2</button>
                <button className={((activePage == 3) ? 'active' : '')} onClick={() => setActivePage(3)}>Bilder / Dateien</button>
                <button className={((activePage == 4) ? 'active' : '')} onClick={() => setActivePage(4)}>Leistungsdaten</button>
                <button className={((activePage == 5) ? 'active' : '')} onClick={() => setActivePage(5)}>Kombinationen</button>
            </div>
          }
          {(newItem[1] || newItem[0])  && 
            <div className='timeline'>
                <div className={((activePage == 0) ? 'active' : '')}><i>1</i>Allgemein</div>
                <div className={((activePage == 1) ? 'active' : '')}><i>2</i>Eigenschaften</div>
                <div className={((activePage == 2) ? 'active' : '')}><i>3</i>Eigenschaften2</div>
                <div className={((activePage == 3) ? 'active' : '')}><i>4</i>Bilder / Dateien</div>
                <div className={((activePage == 4) ? 'active' : '')}><i>5</i>Leistungsdaten</div>
                <div className={((activePage == 5) ? 'active' : '')}><i>6</i>Speichern</div>
            </div>
          }
          {(data && (Object.keys(data).length)) &&  
          <form className='productvariation' ref={formRef} onSubmit={handleSubmit}>
            <div className='form-container'>
              <div id="form-part-1" className={'form-part ' + ((activePage == 0) ? 'active' : '')}>
                <FormItems elements={Elements} handleChange={handleChange} data={data} type={newItem[0] ? 'new' : 'detail'} />
              </div>
              <div id="form-part-2" className={'form-part ' + ((activePage == 1) ? 'active' : '')}>
                <FormItems elements={VariationCharacters} handleChange={handleChange} data={data} type={newItem[0] ? 'new' : 'detail'} />
              </div>
              <div id="form-part-3" className={'form-part ' + ((activePage == 2) ? 'active' : '')}>
                <FormItems elements={additionalVariationCharacters} handleChange={handleChange} data={data} type={newItem[0] ? 'new' : 'detail'} />
                </div>
              <div className={'form-part ' + ((activePage == 3) ? 'active' : '')}>
                <FormItems elements={Images} handleChange={handleChange} data={data} type={newItem[0] ? 'new' : 'detail'} />
              </div>
              <div className={'form-part ' + ((activePage == 4) ? 'active' : '')}>
                <PerformancedataMenu elements={Performance} handleChange={handlePerformanceChange} 
                    data={performanceData} variationdata={data} type={newItem[0] ? 'new' : 'detail'} />
              </div>
              {variationid && 
                <div className={'form-part ' + ((activePage == 5) ? 'active' : '')}>
                  <CombinationTable variationid={variationid} />
                </div>
              }
            </div>
            
            <div className='table-buttons detail'>
              {newItem[1] || newItem[0] && activePage > 0 && 
                <button type="button" onClick={() => setActivePage(activePage - 1)}><i className="fas fa-chevron-left"></i>{global.config.back[language]}</button>
              }
              {activePage == 0 && 
                <div></div>
              }
              <div style={{display:"flex" , gap : 10}}>
              {(newItem[0] || newItem[1]) && activePage < 4 &&
                <button  type="submit"><span>{global.config.next[language]}</span><i className="fas fa-chevron-right"></i></button>}
              {(activePage < 4 && !newItem[0] && !newItem[1]) && 
                  <button className="secondbutton" type="button" onClick={duplicateVariation}><i className="fas fa-copy"></i><span>{global.config.duplicateVariation[language]}</span></button>
              }
              {((activePage < 4 && !newItem[0] && !newItem[1]) || activePage == 4) && 
                  <button className="secondbutton" type="submit"><i className="fas fa-save"></i><span>{global.config.save[language]}</span></button>
              }
              </div>

              {/* <button href={"/productvariations/"} >Zurück zur Tabelle</button> */}
            </div>
          </form>
          }
        </section>

    </div>
  );
}

export default ProductvariationDetail;