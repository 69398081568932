module.exports = global.config = {
    "productsdata" : [],
    "products": {
       "de":"Produkte",
       "en":"Products"
    },
    "exceldownload": {
       "de":"Als Excel downloaden",
       "en":"Download as Excel"
    },
    "generatenew": {
       "de":"Produkte neu generieren",
       "en":"Generate new products"
    },
    "productvariation": {
        "de":"Produktvariation",
        "en":"Productvariation"
     },
    "productcharacteristic": {
        "de":"Produktcharakteristik",
        "en":"Produktcharacteristic"
     },
    "successheadline" : {
      "de": "Super!",
      "en": "Great!"
    },
    "errorheadline" : {
      "de": "Oje!",
      "en": "Oh no!"
    },
    "successtext" : {
      "de": "Die Daten wurden erfolgreich gespeichert",
      "en": "The data was saved successfully"
    }, 
    "errortext" : {
      "de": "The data could not be saved",
      "en": "Die Daten konnten nicht gespeichert werden"
    },
    "productsuccesstext" : {
      "de": "Die Produkte wurden erfolgreich neu generiert",
      "en": "The products were successfully regenerated"
    },
    "producterrortext" : {
      "de": "Die Produkte konnten leider nicht neu generiert werden. Versuche es später erneut",
      "en": "Unfortunately, the products could not be regenerated. Try again later"
    },
    "productvdsuccesstext" : {
      "de": "Die Produktvariationen wurden erfolgreich",
      "en": "The productvariations were successfully deleted"
    },
    "productvderrortext" : {
      "de": "Die Produktvariationen konnten leider nicht gelöscht werden",
      "en": "Unfortunately, the productvariations could not be deleted. Try again later"
    },
    "productvsuccesstext" : {
      "de": "Die Produktvariation wurde erfolgreich gespeichert",
      "en": "The productvariation were saved successfully"
    },
    "productverrortext" : {
      "de": "Die Produktvariation konnte leider nicht gespeichert werden",
      "en": "Unfortunately, the productvariation could not be saved. Try again later"
    },
    "pricesuccesstext" : {
      "de": "Die Preise der Produkte wurden angepasst",
      "en": "The prices of the products have been adjusted"
    },
    "priceerrortext" : {
      "de": "Kontrolliere dein File, die Preise konnten nicht angepasst werden",
      "en": "Check your file, the prices could not be adjusted"
    },
    "loginsuccesstext" : {
      "de": "Sie wurden erfolgreich eingeloggt",
      "en": "You have been logged in successfully"
    },
    "loginerrortext" : {
      "de": "Passwort und username stimmen nicht überein",
      "en": "This email address was not found in the database"
    },
    "loginerroractivetext" : {
      "de": "Ihr Konto wurde deaktiviert. Wenden Sie sich bitte an taner.alkan@kerionlighing.com",
      "en": "Your account has been deactivated. Please contact taner.alkan@kerionlighing.com"
    },
    "emailerrortext" : {
      "de": "Diese Mail-Adresse wurde in der Datenbank nicht gefunden",
      "en": "Password and username do not match"
    },
    "changepwdsuccesstext" : {
      "de": "Das Passwort konnte erfolgreich geändert werden",
      "en": "The password was changed successfully"
    },
    "changepwderrortext" : {
      "de": "Das Passwort konnte nicht geändert werden. Vermutlich stimmt der Link nicht. Kontrolliere ihn noch einmal nach",
      "en": "The password could not be changed. The link is probably not correct. Check it again"
    },
    "registersuccesstext" : {
      "de": "Sie wurden erfolgreich registriert. Nach Bestätigung der Emailadresse können Sie sich einloggen",
      "en": "You have been successfully registered. After confirming your email address you can log in"
    },
    "registererrortext" : {
      "de": "Die Registrierung hat leider nicht funktioniert",
      "en": "Unfortunately the registration didn't work"
    },
    "offersuccesstext" : {
      "de": "Das Angebot wurde erfolgreich gespeichert",
      "en": "The offer was saved successfully"
    },
    "offererrortext" : {
      "de": "Das Angebot konnte nicht gespeichert werden, überprüfe deine Angaben",
      "en": "The offer could not be saved, check your details"
    },
    "offer" : {
      "de": "Angebote",
      "en": "Offer"
    },
    "newoffer" : {
      "de": "Neues Angebot",
      "en": "New Offer"
    },
    "addprices" : {
      "de": "Preise hochladen",
      "en": "Upload prices"
    },
    "addvariation" : {
      "de": "Variation hinzufügen",
      "en": "Add variation"
    },
    "newvariation" : {
      "de": "Neue Produktvariation",
      "en": "New Productvariation"
    },
    "next" : {
      "de": "weiter",
      "en": "next"
    },
    "save" : {
      "de": "speichern",
      "en": "save"
    },
    "back" : {
      "de": "zurück",
      "en": "back"
    }, 
    "id" : {
      "de": "ID",
      "en": "ID"
    },
    "color" : {
      "de": "Farbe",
      "en": "Color"
    },
    "colors" : {
      "de": "Farben",
      "en": "Colors"
    },
    "optic" : {
      "de": "Abstrahlwinkel",
      "en": "Optic"
    },
    "opticname" : {
      "de": "Abstrahlwinkel",
      "en": "Optic"
    },
    "cover" : {
      "de": "Abdeckung",
      "en": "Cover"
    },
    "dimmable" : {
      "de": "Dimmbar",
      "en": "Dimmable"
    },
    "lighttemperature" : {
      "de": "Lichttemperatur",
      "en": "Lighttemperature"
    },
    "cri" : {
      "de": "Cri",
      "en": "Cri"
    },
    "watt" : {
      "de": "Watt",
      "en": "Watt"
    },
    "lumen" : {
      "de": "Lumen",
      "en": "Lumen"
    },
    "length_mm" : {
      "de": "Länge",
      "en": "Length"
    },
    "width_mm" : {
      "de": "Breite",
      "en": "Width"
    },
    "height_mm" : {
      "de": "Höhe",
      "en": "Height"
    },
    "diameter_mm" : {
      "de": "Durchmesser",
      "en": "Diameter"
    },
    "weight" : {
      "de": "Gewicht",
      "en": "Weight"
    },
    "outline" : {
      "de": "Schnitt",
      "en": "Outline"
    },
    "woodoption" : {
      "de": "Holzoptionen",
      "en": "Woodoption"
    },
    "woodsurface" : {
      "de": "Holzoberfläche",
      "en": "Woodsurface"
    },
    "masse" : {
      "de": "Maße",
      "en": "Size"
    },
    "productcategories" : {
      "de": "Produktkategorien",
      "en": "Productcategories"
    },
    "performance_price" : {
      "de": "Preis",
      "en": "Price"
    },
    "deleterows" : {
      "de": "ausgewählte Reihen löschen",
      "en": "delete selected rows"
    },
    "deleteuser" : {
      "de": "Wollen Sie diesen User wirklich löschen?",
      "en": "Do you really want to delete this user?"
    },
    "successdeleteuser" : {
      "de": "Der User wurde erfolgreich gelöscht",
      "en": "The user was successfully deleted"
    },
    "errordeleteuser" : {
      "de": "Der User konnte nicht gelöscht werden",
      "en": "The user could not be deleted"
    },
    "anr" : {
      "de": "Artikelnummer",
      "en": "Articlenumber"
    },
    "power" : {
      "de": "Leistung [W]",
      "en": "Power [W]"
    },
    "lighttemperature" : {
      "de": "Lichttemperatur [K]",
      "en": "Lighttemperature [K]"
    },
    "luminous" : {
      "de": "Lichtstrom [lm]",
      "en": "Luminous flux [lm]"
    },
    "tension" : {
      "de": "Spannung",
      "en": "Tension"
    },
    "variationimage" : {
      "de": "Variationsbild",
      "en": "Variationimage"
    },
    "variationperformance" : {
      "de": "Variations-Leistungen",
      "en": "Variationperformance"
    },
    "productperformance" : {
      "de": "Produkt-Leistungen",
      "en": "Productperformance"
    },
    "amount" : {
      "de": "Menge",
      "en": "Amount"
    },
    "performance_price_dali" : {
      "de": "Preis (dali)",
      "en": "Price (dali)"
    },
    "performance_price_110V" : {
      "de": "Preis (1-10V)",
      "en": "Price (1-10V)"
    },
    "lumen_indirect" : {
      "de": "Lumen (indirekt)",
      "en": "Lumen (indirect)"
    },
    "mandatory" : {
      "de": "Dies ist ein Pflichtfeld",
      "en": "This is a mandatory field"
    },
    "back" : {
      "de": "Zurück",
      "en": "Back"
    },
    "continue" : {
      "de": "Weiter",
      "en": "Continue"
    },
    "alreadymember" : {
      "de": "Sie haben bereits ein Konto?",
      "en": "Already have an account?"
    },
    "backtotable" : {
      "de": "Zurück zur Übersicht",
      "en": "Back to Overview"
    },
    "next" : {
      "de": "weiter",
      "en": "next"
    },
    "createnewoffer" : {
      "de": "Neues Angebot erstellen",
      "en": "Create new Offer"
    },
    "addproducts" : {
      "de": "Produkte hinzufügen",
      "en": "Add Products"
    },
    "defineamount" : {
      "de": "Menge definieren",
      "en": "Define Amount"
    },
    "productgroup" : {
      "de": "Produktgruppe",
      "en": "Productgroup"
    },
    "checkletterhead" : {
      "de": "Briefkopf kontrollieren",
      "en": "Check letterhead"
    },
    "createpreview" : {
      "de": "Vorschau / Angebot erstellen lassen",
      "en": "Have a preview/offer created"
    },
    "noproductselected": {
      "de": "Du hast noch keine Produkte gewählt",
      "en": "You haven't selected any products yet"
    },
    "totalprice": {
      "de": "Gesamtpreis (netto)",
      "en": "Total price (net)"
    }	,
    "grossprice": {
      "de": "Gesamtpreis (brutto)",
      "en": "Total price (gross)"
    }	,
    "vat": {
      "de": "Mehrwertsteuer (20%)",
      "en": "VAT (20%)"
    }	,
    "sharetoediting": {
      "de": "Angebot anfordern",
      "en": "request a quote"
    },
    "upload": {
      "de": "hochladen",
      "en": "upload"
    }	,
    "uploadprices": {
      "de": "Preise hochladen",
      "en": "Upload prices"
    }	,
    "login": {
      "de": "Einloggen",
      "en": "Log in"
    }	,
    "password": {
      "de": "Passwort",
      "en": "Password"
    },
    "passwordrepeat": {
      "de": "Passwort (wiederholung)",
      "en": "Password (repeat)"
    }	,
    "register": {
      "de": "Registrieren",
      "en": "Register"
    }	,
    "resetpwd": {
      "de": "Passwort zurücksetzen",
      "en": "Reset Password"
    }	,
    "forgotpwd": {
      "de": "Passwort vergessen?",
      "en": "Forgot Password?"
    }	,
    "phone": {
      "de": "Telefon",
      "en": "Phone"
    },	
    "unequal" : {
      "de": "Die Passwörter stimmen nicht überein",
      "en": "The passwords do not match"
    },
    "emailnotverified" : {
      "de" : "Ihre Emailadresse wurde noch nicht verifiziert. Klicken Sie auf den Link in der Registrierungsmail.",
      "en" : "Your email address has not yet been verified. Click on the link in the registration email."
    },
    "becomeB2Bpartner" : {
      "de" : "B2B Partner werden",
      "en" : "Become a B2B partner"
    },
    "alreadyregistered" : {
      "de" : "Diese Emailadresse wurde bereits registriert",
      "en" : "This email address has already been registered"
    },
    "passwordcheck" : {
      "de" : "Das Passwort muss mindestens aus 8 Character bestehen und 1 Sonderzeichen und 1 Ziffer enthalten",
      "en" : "The password must consist of at least 8 characters and contain 1 special character and 1 number"
    },
    "mailresetpwd" : {
      "de" : "Sie erhalten in Kürze eine Mail um Ihr Passwort zurückzusetzen",
      "en" : "You will shortly receive an email to reset your password"
    },
    "pwdcouldntreset" : {
      "de" : "Ihr Passwort konnte nicht zurückgesetzt werden. Bitte wenden Sie sich an den Administrator",
      "en" : "Your password could not be reset. Please contact the administrator"
    },
    "mailnotindb" : {
      "de" : "Diese Mailadresse wurde nicht in der Datenbank gefunden",
      "en" : "This email address was not found in the database"
    },
    "offerdeleted" : {
      "de" : "Dieses Angebot wurde leider gelöscht - oder hat nie existiert.",
      "en" : "Unfortunately, this offer has been deleted - or never existed."
    },
    "createnewofferorback" : {
      "de" : "Erstelle ein neues Angebot oder gehe zurück zu deiner Tabelle",
      "en" : "Create a new offer or go back to your spreadsheet"
    },
    "viewmyoffer" : {
      "de" : "Mein Angebot ansehen",
      "en" : "View my offer"
    },
    "offersent" : {
      "de" : "Sie haben das Angebot erfolgreich abgeschickt. Wir werden uns sobald wie möglich bei Ihnen melden",
      "en" : "You have successfully submitted the offer. We will contact you as soon as possible"
    },
    "offerfrom" : {
      "de" : "Angebot von",
      "en" : "Offer from"
    },
    "offerstatus" : {
      "de" : "Angebotstatus",
      "en" : "Offerstatus"
    },
    "markasdone" : {
      "de" : "Als erledigt markieren",
      "en" : "Mark as done"
    },
    "markasnew" : {
      "de" : "Als neu markieren",
      "en" : "Mark as undone"
    },
    "viewofferfrom" : {
      "de" : "Angebot ansehen von",
      "en" : "View offer from"
    },
    "editoffer" : {
      "de" : "Angebot bearbeiten",
      "en" : "Edit offer"
    },
    "privacyaccept" : {
      "de" : "Sie müssen den Datenschutz akzeptieren um sich registrieren zu können",
      "en" : "You must accept data protection in order to register"
    },
    "discountacception" : {
      "de" : "Ihr Rabatt wird erst bei Überprüfung des Angebots berücksichtigt",
      "en" : "Your discount will only be taken into account when the offer is checked"
    },
    "duplicateVariation" : {
      "de" : "Variation duplizieren",
      "en" : "Duplicate Variation"
    },
    "catalogue" : {
      "de" : "Hauptkatalog",
      "en" : "Main catalogue"
    },
    "discover" : {
      "de" : "entdecken",
      "en" : "discover"
    },
    "productfolder" : {
      "de" : "Produktfolder",
      "en" : "Productfolder"
    },
    "makecontact" : {
      "de" : "Kontakt aufnehmen",
      "en" : "make contact"
    }
}


