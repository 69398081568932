import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../../Css/main.css';
import Sidemenu from '../Sidemenu';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import FormItems from './FormItems';
import {Elements, ElementsEn} from './UserDetailConfig';
import Loading from '../Helper/Loading';


function UserDetail() {
  let { character } = useParams();
  const [data, setData] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  let userid = searchParams.get("user");
  let newItem = searchParams.get("new") ? JSON.parse(searchParams.get("new")) : false;

  const formRef = useRef(null);
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});

  let language = localStorage.getItem("kerion_language");
  let user = JSON.parse(localStorage.getItem('kerion_user')); 

  if(!localStorage.getItem('kerion_user')) {
    window.location.href = '/login';
  }

  useEffect(() => {

    if(!character != 'new') {
      axios.get(process.env.REACT_APP_URL + 'getData.php?user='+userid)
      .then(response =>  {
        console.log(response)
        setData(response.data[0]);

      })
      .catch(error => console.log(error));
    }else {
      console.log('new');
    }
  }, []);


  const handleSubmit = (event) => {
    setLoading({'display': true, 'circles': true, 'dialog': false, 'error' : false});
    event.preventDefault();

      axios.post(process.env.REACT_APP_URL + 'setData.php?user=true', data)
      .then(response =>  {
        console.log(response);
        if(response.data[0] == true){
          setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : false});
          localStorage.setItem('kerion_user', JSON.stringify(data));
        }
        else 
          setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : true});

      })
      .catch(error => console.log(error));
    
  }

  function handleChange(key, value, array)  {
    setData({...data, [key]: value});
  }
  function handleOk() {
    setLoading({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  }

  return (
    <div className="main">
      <Loading
            loading={loading}         
            headline={{'success' : global.config.successheadline[language], 'error' : global.config.errorheadline[language]}}
            text={{'success' :global.config.successtext[language], 'error' : global.config.errortext[language]}}
            button={{'ok' : true}}
            handleOk={handleOk} 
        />
        <Sidemenu/>
        {((user.roles.indexOf('ADMIN') > -1) || user.id ==  userid ) && 
          <section className='userform'>
            <h1>Userdetail</h1>
            <form className="form-height-100">
              <div>
                  {data && <FormItems elements={language == 'en' ? ElementsEn : Elements} handleChange={handleChange} data={data} type={newItem ? 'new' : 'detail'} />}
              </div>
              <div className='table-buttons'>
                <a className="button" href={"/users"} >
                  <i className='fas fa-table'></i>
                  {global.config.backtotable[language]}
                 </a>
                <button className="secondstyle" onClick={handleSubmit} type="submit">
                  <i className='fas fa-save'></i>{global.config.save[language]}</button>
              </div>
            </form>
          </section>
        }{((user.roles.indexOf('ADMIN') == -1) && user.id !=  userid ) &&
            <section className="errorview" >
              <h1>Userdetail</h1>
              <h3>Sie sind nicht befugt diese Ansicht zu sehen / zu verändern</h3>
            </section>
        }
        

    </div>
  );
}

export default UserDetail;