export const Elements = [
    /*{
        label: 'Name*',
        field: 'fullname',
        htmlfield: "input",
        type: 'text',
        width: 200,
        required: true
    },
    {
        label: 'Telefon',
        field: 'phone',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Adresse',
        htmlfield: "headline",
        type: 'h4',
        width: 200,
    },
    {
        label: 'Straße',
        field: 'street',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Hausnummer',
        field: 'housenumber',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'PLZ',
        field: 'zip',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Ort',
        field: 'city',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Land',
        field: 'country',
        htmlfield: "select",
        type: 'countryselect',
        width: 200,
    },
    {
        label: 'Bank',
        htmlfield: "headline",
        type: 'h4',
        width: 200,
    },
    {
        label: 'Bank',
        field: 'bank',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'IBAN',
        field: 'iban',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'BIC',
        field: 'bic',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Firmenbuchnummer',
        field: 'company_register_number',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Steuernummer',
        field: 'tax_number',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },
    {
        label: 'Zusatzinfo',
        field: 'zk',
        htmlfield: "wysiwig",
        type: 'textarea',
        width: 200,
    },
    {
        label: 'Rabatt',
        field: 'discount',
        htmlfield: "input",
        type: 'text',
        width: 200,
    },*/
    {
        label: 'Newsletter abonnieren',
        field: 'newsletter',
        htmlfield: "checkbox",
        type: 'singlecheckbox',
        width: 200,
    },
]
