import { NavLink } from "react-router-dom";

export const columns = [
    {
        headerName: "Belegnummer",
        field: "offernumber",
        flex: 2,
        width: 300,
        minWidth: 300, 
        maxWidth: 250,
        renderCell: (params) => {
            return <NavLink className={"statistic-table-link"} to={`/offers/offer?offer=${params.row['id']}`} title={params.value}>{params.value}</NavLink>
        }
    },
    {
        headerName: 'Erstellt von',
        field: 'fullname',
        width: 100,
    },
    {
        headerName: 'ID',
        field: 'id',
        width: 100,
    },
    {
        headerName: 'Erstellungsdatum',
        field: 'created',
        width: 200,
    },
    {
        headerName: 'Status',
        field: 'status',
        width: 170,
        renderCell: (params) => {
            return <div className="status"><div className={params.row['status']}></div> &nbsp; {params.row['status'] == 'new' ? 'NEU' : 'Bearbeitet'}</div>; 
        }
    },
    {
        headerName: 'Abgeschickt',
        field: 'ordered',
        width: 170,
        renderCell: (params) => {
            return <div className="status"><div className={'ordered-' + params.row['ordered']}></div> &nbsp;  {params.row['ordered'] == 0 ? 'Entwurf' : 'Abgeschickt'} </div>; 
        }
    },
    /*{
        headerName: 'Email verifiziert',
        field: 'email_verified',
        width: 100,
    }*/
]


export const columns_en = [
    {
        headerName: "Offernumber",
        field: "offernumber",
        flex: 2,
        width: 300,
        minWidth: 300, 
        maxWidth: 250,
        renderCell: (params) => {
            return <NavLink className={"statistic-table-link"} to={`/offers/offer?offer=${params.row['id']}`} title={params.value}>{params.value}</NavLink>
        }
    },
    {
        headerName: 'Created by',
        field: 'fullname',
        width: 100,
    },
    {
        headerName: 'ID',
        field: 'id',
        width: 100,
    },
    {
        headerName: 'Create Date',
        field: 'created',
        width: 200,
    },
    {
        headerName: 'State',
        field: 'status',
        width: 170,
        renderCell: (params) => {
            return <div className="status"><div className={params.row['status']}></div> &nbsp; {params.row['status'] == 'new' ? 'NEW' : 'edited'}</div>; 
        }
    },
    {
        headerName: 'Sent',
        field: 'ordered',
        width: 170,
        renderCell: (params) => {
            return <div className="status"><div className={'ordered-' + params.row['ordered']}></div> &nbsp;  {params.row['ordered'] == 0 ? 'Draft' : 'Sent'} </div>; 
        }
    },
    /*{
        headerName: 'Email verifiziert',
        field: 'email_verified',
        width: 100,
    }*/
]
