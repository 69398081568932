import '../Css/main.css';
import Sidemenu from './Sidemenu';
import axios from 'axios';
import {useEffect, useState} from 'react';
import { useGridApiRef } from '@mui/x-data-grid';
import {Table} from './Tables/Table';
import Loading from './Helper/Loading';
import { deleteCookie } from './Helper/cookiefuntions';

function User() {
  let [data, setData] = useState();
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  let language = localStorage.getItem("kerion_language");
  const [dialog, setDialog] = useState({'success': global.config.deleteuser[language], 'error' : global.config.errordeleteuser[language]});
  const [userIds, setUserIds] = useState([]);
  const apiRefTable = useGridApiRef();
  
  if(!localStorage.getItem('kerion_user')) {
    localStorage.setItem('loggedIn', false);
    deleteCookie('kerion_login');
    window.location.href = '/login';
  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_URL + 'getData.php?user=true')
      .then(response =>  {
        console.log(response)
        setData(response.data);
      })
      .catch(error => console.log(error));
  }, []);


  function deleteUser(ids) {
    setLoading({'display': true, 'circles': true, 'dialog': true, 'error' : false, 'warning': true});
    setUserIds(ids);
  }

  function handleOk() {
    if(loading.warning) {
      axios.post(process.env.REACT_APP_URL + 'deleteData.php?user=true', {'ids' : userIds})
      .then(response =>  {
        console.log(response);

        setDialog({...dialog, success : global.config.successdeleteuser[language]})

        if(response.data[0] == true) 
          setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : false, 'warning': false});
        else
          setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : true, 'warning': false});
      })
      .catch(error => console.log(error));
    }else {
      window.location.reload();
    }
    
  }
  function handleClose() {
    setLoading({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  }

  return (
    <div className='main'>
       <Loading
            loading={loading}         
            headline={{'success' : global.config.successheadline[language], 'error' : global.config.errorheadline[language], 'warning' : 'Vorsicht!'}}
            text={{'success': dialog.success , 'error' : dialog.error}}
            button={{'ok' : true}}
            handleOk={handleOk} 
            handleClose={handleClose} 
        />
        
        <Sidemenu />
        <section>
          <h1>User</h1>

          {data && 
            <Table data={data} mode="user" 
              deletebutton={true} 
              onDelete={deleteUser} 
              apiRef={apiRefTable}
             />
          }
        </section>
    </div>
  );
}

export default User;