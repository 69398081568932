import { useGridApiRef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import '../Css/main.css';
import Sidemenu from './Sidemenu';
import {Table} from './Tables/Table';
import { columns as productColumns, excelColumns as excelColumns } from "./Tables/ProductConfig";
import axios from 'axios';
import Loading from './Helper/Loading';
import { exportExcel, exportExcelData } from './Helper/excel';
import { deleteCookie } from './Helper/cookiefuntions';


function Products() {
  const [data, setData] = useState([]);
  const apiRefTable = useGridApiRef();
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  const [mode, setMode] = useState('productUser');

  let user = JSON.parse(localStorage.getItem('kerion_user'));
  let language = localStorage.getItem("kerion_language");

  if(!localStorage.getItem('kerion_user')) {
      localStorage.setItem('loggedIn', false);
      deleteCookie('kerion_login');
      window.location.href = '/login';
  }

  useEffect(() => {
    
    if(user.roles.indexOf('ADMIN') > 0) setMode('product');
    else if(user.is_verified == '0') setMode('productUserV');

    if(!global.config.productsdata.length) {    
      setLoading({'display': true, 'circles': true, 'dialog': false, 'error' :false});

      axios.get(process.env.REACT_APP_URL + 'getData.php')
          .then(response =>  {
            console.log(response)
            setData(response.data);
            global.config.productsdata = response.data;
            setLoading({'display': false, 'circles': false, 'dialog': false, 'error' :false});
          })
          .catch(error => console.log(error));
      }else {
        setData(global.config.productsdata);
      }

      
  }, []);
  

  function generateDatasheet(cell) {
    if(cell.field == 'datenblatt' || cell.field == 'datenblatt_en') {
          let product = {...data[cell.id - 1]};
          product['lang'] = language;
          if(cell.field == 'datenblatt_en')
            product['lang'] = 'en';

          console.log(product);

          
          axios.post(process.env.REACT_APP_PDF_URL + 'createpdf_portal.php', {data : product})
            .then(response =>  {
              console.log(response);

              if(response.data[0])
                window.open(process.env.REACT_APP_PDF_URL + 'pdfs/' + response.data[0], '_blank');
                //window.open('http://localhost/kerionportal/pdfs/pdfs/Datenblatt-Andonis-55.pdf', '_blank');
            })
            .catch(error => console.log(error));

    }
  }

  function regenerateProducts () {
    setLoading({'display': true, 'circles': true, 'dialog': false, 'error' :false});


    axios.get(process.env.REACT_APP_URL + 'regenerateProducts.php?regeneration=79111', {'timeout' : 300000})
      .then(response =>  {
        console.log(response)
        //setData(response.data);
        //global.config.productsdata = response.data;

        if(response.data[0] == true)  {
          //setData(response.data[2]);
          //global.config.productsdata = response.data[2];
          setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : false});
        }
        else
          setLoading({'display': true, 'circles': false, 'dialog': true, 'error' : true});
      
      })
      .catch(error => console.log(error.response.data));

  };

  function handleOk() {
      window.location.reload();
  }

  async function downloadProducts() {
    /*const columns = productColumns.map(it => ({
      header: it.headerName, key: it.field
    }));*/
    const columns = excelColumns.map(it => ({
      header: it.headerName, key: it.field
    }));

    console.log(data, columns);

    await exportExcelData(data, columns);
  }



  return (
    <div className="main">
       <Loading
            loading={loading}         
            headline={{'success' : global.config.successheadline[language], 'error' : global.config.errorheadline[language]}}
            text={{'success' :global.config.productsuccesstext[language], 'error' : global.config.producterrortext[language]}}
            button={{'ok' : true}}
            handleOk={handleOk} 
        />
        
        <Sidemenu/>
        <section>
          <h1>{global.config.products[language]}</h1>
          
          {data && 
            <Table data={data} 
                mode={mode} 
                tablebuttons={false} 
                cellClicked={(data) => generateDatasheet(data)}
                apiRef={apiRefTable} />
          }
          <div className="table-buttons top">
              <button onClick={downloadProducts}><i className="fas fa-download"></i>{global.config.exceldownload[language]}</button>
              {user.roles.indexOf('ADMIN') > 0 && <button onClick={regenerateProducts}><i className="fas fa-wand-magic-sparkles"></i>{global.config.generatenew[language]}</button>}
          </div>
        </section>
    </div>
  );
}

export default Products;