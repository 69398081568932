import '../Css/main.css';
import Sidemenu from './Sidemenu';
import axios from 'axios';
import {useEffect, useState} from 'react';
import {Table} from './Tables/Table';
import Loading from './Helper/Loading';
import { deleteCookie } from './Helper/cookiefuntions';

function Userlogin() {
  let [data, setData] = useState();
  const [loading, setLoading] = useState({'display': false, 'circles': false, 'dialog': false, 'error' : false});
  let language = localStorage.getItem("kerion_language");

  if(!localStorage.getItem('kerion_user')) {
    localStorage.setItem('loggedIn', false);
    deleteCookie('kerion_login');
    window.location.href = '/login';
  } 

  useEffect(() => {
    axios.get(process.env.REACT_APP_URL + 'getData.php?user=logindata')
      .then(response =>  {
        console.log(response)
        setData(response.data);
      })
      .catch(error => console.log(error));
  }, []);


  return (
    <div className='main'>

        <Sidemenu />
        <section>
          <h1>Userlogins</h1>

          {data && 
            <Table 
              data={data} 
              mode="userlogin" 
             />
          }
        </section>
    </div>
  );
}

export default Userlogin;